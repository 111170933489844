// src/modules/wallet/presentation/components/ActiveWallet/presentational/ActiveWalletPresentational.tsx

import { Center, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

import { CancelButtonContainer } from '../../Wrapper/container/CancelButtonContainer'
import { SubmitButtonContainer } from '../../Wrapper/container/SubmitButtonContainer'
import { WrapperContainer } from '../../Wrapper/container/WrapperContainer'

type ActiveWalletPresentationalProps = {
  walletName: string
  onClose: () => void
  handleActiveWallet: () => void
  isLoadingActive: boolean
  isOpen: boolean
}

export default function ActiveWalletPresentational({
  walletName,
  onClose,
  handleActiveWallet,
  isLoadingActive,
  isOpen
}: ActiveWalletPresentationalProps) {
  if (!isOpen) return null

  return (
    <WrapperContainer>
      <Header />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            Ativar carteira
          </Text>

          <Flex gap="4px" fontSize="20px" color="#5A5469" letterSpacing="-1px">
            <Text fontWeight="500">Deseja ativar a carteira</Text>
            <Text fontWeight="700">{walletName}?</Text>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <CancelButtonContainer onClick={onClose} />
          <SubmitButtonContainer
            onClick={handleActiveWallet}
            title="Ativar carteira"
            isLoading={isLoadingActive}
            isDisabled={false}
          />
        </Flex>
      </Flex>
    </WrapperContainer>
  )
}

function Header() {
  return (
    <Center h="160px" w="100%" bg="#D7FCEA">
      <Flex
        position="relative"
        _before={{
          content: "''",
          position: 'absolute',
          top: '128px',
          left: 'calc(50% - 24px)',
          width: '48px',
          height: '24px',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: "url('/img/wallet/activeArrowDown.svg')",
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Image
          w="96px"
          h="96px"
          alt="Active Wallet Icon"
          src="/img/wallet/activeWalletIcon.svg"
        />
      </Flex>
    </Center>
  )
}
