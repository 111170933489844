import { BlockReason } from 'models/BlockReason'
import { UnblockCard } from 'models/request/UnblockCard'
import { formatDateToFilter } from 'utils/dateUtils'

import { consumerApi } from './instances/consumerApi'

export type UpdateStatus = {
  id: number
  status: string
}

export const AccountService = {
  getCards: (accountId: number) => {
    return consumerApi.get(`/accounts/${accountId}/cards`, {
      data: null
    })
  },
  getEvents: (accountId: number) => {
    const todayDate = new Date()

    const fiveYearsInMonth = 60
    const defaultDtStart = formatDateToFilter(
      new Date(todayDate.setMonth(todayDate.getMonth() - fiveYearsInMonth))
    )

    const defaultDtEnd = formatDateToFilter(new Date())

    return consumerApi.get(`/accounts/${accountId}/history`, {
      data: null,
      params: { dtStart: defaultDtStart, dtEnd: defaultDtEnd }
    })
  },
  getEventsByFilter: ({
    dtStart,
    dtEnd,
    accountId
  }: {
    dtStart?: string
    dtEnd?: string
    accountId?: string
  }) => {
    const todayDate = new Date()

    const fiveYearsInMonth = 60
    const defaultDtStart = formatDateToFilter(
      new Date(todayDate.setMonth(todayDate.getMonth() - fiveYearsInMonth))
    )

    const defaultDtEnd = formatDateToFilter(new Date())

    const dateStart = dtStart
      ? `dtStart=${dtStart}`
      : `dtStart=${defaultDtStart}`
    const dateEnd = dtEnd ? `dtEnd=${dtEnd}` : `dtEnd=${defaultDtEnd}`

    return consumerApi.get(
      `/accounts/${accountId}/history?${dateStart}&${dateEnd}`,
      {
        data: null
      }
    )
  },
  blockCard: async ({
    cardId,
    reason,
    productId
  }: {
    cardId: number
    reason: BlockReason
    productId: number
  }) => {
    return consumerApi.post(
      `/cards/${cardId}/block`,
      {
        reason: reason,
        productId
      },
      { data: null }
    )
  },
  unblockCard: async ({ cardId, productId, reason }: UnblockCard) => {
    return consumerApi.post(
      `/cards/${cardId}/unblock`,
      {
        reason: reason,
        productId
      },
      {
        data: null
      }
    )
  },
  getAccountHistory: async ({
    accountId,
    startDate,
    endDate,
    filters,
    page
  }: {
    accountId?: number
    startDate?: string
    endDate?: string
    filters?: {
      event?: string[]
      reason?: string
      onlyCards?: boolean
      onlyAccountWallets?: boolean
    }
    page?: number
  }) => {
    if (!accountId) throw new Error('Account ID is required')

    const params = new URLSearchParams()
    if (page) params.append('page', page.toString())

    const response = await consumerApi.post(
      `/accounts/${accountId}/history?${params.toString()}`,
      { ...filters, startDate, endDate }
    )
    return response
  }
}
