import { format, parseISO } from 'date-fns'

import { insertZero } from './csvUtils'

export const formatToDateAndTime = (value: string, removeLetters?: boolean) => {
  const formattedValue = new Date(value)
  const formattedDate = formatDateToBrazilianFormat(
    formattedValue.toISOString()
  )

  const formattedHours = formattedValue.toLocaleString('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'America/Sao_Paulo'
  })

  const letters = removeLetters ? '' : 'às'

  return `${formattedDate} ${letters} ${formattedHours}`
}

export const formatDateToBrazilianFormat = (date: string | undefined) =>
  format(parseISO(date ?? new Date().toISOString()), 'dd/MM/yyyy')

export const getCurrentYear = () => {
  return new Date().getFullYear()
}

export const getMonthAndYear = (date: Date) => {
  const year = date.getUTCFullYear()
  const month = date.getMonth() + 1

  const formattedYear = String(year).substring(2, 4)

  const formattedDate = `${insertZero(month)}/${formattedYear}`
  return formattedDate
}

export const formatDateToFilter = (value: Date | null) => {
  if (!value) return null

  const year = value.getUTCFullYear()
  const month = insertZero(value.getUTCMonth() + 1)
  const day = insertZero(value.getUTCDate())

  return `${year}-${month}-${day}`
}
