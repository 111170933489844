import { Container, Layout } from 'components/ui'
import React from 'react'

import 'react-loading-skeleton/dist/skeleton.css'
import AccountHistoryContainer from './History/HistoryContainer'

const AccountHistory = () => {
  return (
    <Layout
      onPageBack={() => window.localStorage.removeItem('accountHistoryFilters')}
      isShowBackButton
      colorScheme="secondary"
      maxWidth="1126px"
    >
      <Container>
        <AccountHistoryContainer />
      </Container>
    </Layout>
  )
}

export default AccountHistory
