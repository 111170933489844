import { useDisclosure } from '@chakra-ui/react'
import { Text, Tag } from 'components/atoms'
import { Table, TransactionStatus } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useProfile } from 'contexts/profileContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { usePagination } from 'hooks/usePagination'
import { translate } from 'internationalization'
import { WalletTransaction } from 'models/WalletTransaction'
import { useTransaction } from 'modules/transaction/presentation/contexts/TransactionContext'
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

import { TransactionDetailsModal } from '../../modals/transactionDetails'
import { CreditReversal } from '../../operations/creditReversal'
import { AppetizerOptions } from '../../options/appetizerOptions'

export const AppetizersTable = () => {
  const [isFetching, setIsFetching] = useState(false)
  const { fetchTransactionByUUID } = useTransaction()

  const {
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isBackoffice4c,
    isManageTransactions,
    isOperation3c,
    isPayments,
    user
  } = useProfile()

  const {
    isLoading,
    transactions,
    getRefundableHistory,
    isLoadingWithFilters,
    selectedTransaction,
    setOriginalTransactionNSU,
    setRefundableHistory,
    setOriginalTransactionUUID,
    setOriginalTransaction,
    getTransactiosByFilter,
    setSelectedTransaction,
    originalTransactionNSU,
    walletTransactionsPagination
  } = useWalletTransaction()

  const is3cProfile = user?.role?.product_id === 34

  const { refreshWalletList } = useAccountWallet()

  const {
    isOpen: isOpenOutboundTransaction,
    onOpen: onOpenDetailsOutboundTransaction,
    onClose: onCloseOutboundTransaction
  } = useDisclosure()

  const { currentPage, handleOnChangePage } = usePagination({
    initialPage: walletTransactionsPagination?.currentPage ?? 0,
    externalPage: walletTransactionsPagination?.currentPage
  })

  const fetchTransactions = async (pageNumber: number) => {
    if (isFetching) return
    setIsFetching(true)
    const storedFilters = JSON.parse(
      localStorage.getItem('currentFilters') || '{}'
    )
    const fetchFilters = {
      ...storedFilters,
      page: pageNumber,
      operation: '%2B'
    }
    await getTransactiosByFilter(fetchFilters)
    setIsFetching(false)
  }

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber === currentPage) return
    handleOnChangePage(pageNumber)
    fetchTransactions(pageNumber)
  }

  const clearFilters = () => {
    localStorage.removeItem('currentFilters')
  }

  useEffect(() => {
    fetchTransactions(currentPage)
    return () => clearFilters()
  }, [])

  useEffect(() => {
    const handleBeforeUnload = () => clearFilters()
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenCreditReversal,
    onOpen: onOpenCreditReversal,
    onClose: onCloseCreditReversal
  } = useDisclosure()

  const columns: CellProps[] = [
    {
      title: 'Data e hora',
      width: '140px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="150%"
            color={isUndoneTransaction ? '#969698' : '#463F5F'}
          >
            {formatToDateAndTime(data.transactionDate, true)}
          </Text>
        )
      }
    },
    {
      title: 'Descrição',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        const capitalizedDescription = capitalizeSentence(data.description)

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            {limitString(capitalizedDescription, 50)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.value'),
      width: '100px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontWeight="700"
            fontSize="14px"
            textDecor={isUndoneTransaction ? 'line-through' : 'initial'}
            color={isUndoneTransaction ? '#969698' : '#0F2365'}
          >
            {formatToBrl(Number(data.value))}
          </Text>
        )
      }
    },
    {
      title: 'Trilha',
      hidden: is3cProfile,
      width: '100px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <Tag
            bg="#fff"
            borderRadius="none"
            border="1px solid #908C99"
            p="4px 8px"
            color="#463F5F"
            fontWeight="500"
          >
            {data.voucherTransaction ? 'Voucher' : 'Crédito'}
          </Tag>
        )
      }
    },
    {
      title: translate('commons.status'),
      width: '96px',
      render: (data: Record<keyof WalletTransaction, any>) => {
        return (
          <TransactionStatus
            status={data.status}
            responseCode={data.responseCode}
          />
        )
      }
    },
    {
      title: 'Tipo',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isUndoneTransaction = data.status === 'U'

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isUndoneTransaction ? '#969698' : '#221C46'}
          >
            {TransactionUtils.formatTransactionType({ type: data.type })}
          </Text>
        )
      }
    },
    {
      title: '',
      width: '5%',
      render: (data: Record<keyof WalletTransaction, any>) => {
        const isShowCreditReversal =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E' &&
          data.type === 'CTR'

        return (
          <AppetizerOptions
            data={data}
            isShowCreditReversal={isShowCreditReversal}
            onOpenDetails={onOpenDetails}
            onOpenCreditReversal={onOpenCreditReversal}
            setSelectedTransaction={setSelectedTransaction}
          />
        )
      }
    }
  ]

  let minimumConsumersForPagination = 20
  if (walletTransactionsPagination?.lastPage) minimumConsumersForPagination = 1

  const isPaginated =
    transactions.length >= minimumConsumersForPagination &&
    Number(walletTransactionsPagination!.totalPages) > 1

  const handleOnClickRow = (data: any) => {
    setSelectedTransaction(data)
    setOriginalTransaction(undefined)
    setOriginalTransactionNSU(undefined)
    setOriginalTransactionUUID(undefined)

    const isExecutedTransaction = data.status === 'E'

    if (data.type === 'PTX') {
      onOpenDetails()

      if (isExecutedTransaction) {
        getRefundableHistory({ transactionUUID: data.transactionUUID! })
      } else {
        setRefundableHistory(undefined)
      }
    } else {
      if (data.type === 'PRC' || data.type === 'CTR' || data.type === 'PCR') {
        if (isExecutedTransaction) {
          getRefundableHistory({ transactionUUID: data.originUUID! })

          fetchTransactionByUUID({ uuid: data.originUUID! }).then(
            originalTransaction => {
              setOriginalTransaction(originalTransaction)
              setOriginalTransactionNSU(originalTransaction.nsu)
              setOriginalTransactionUUID(originalTransaction.transactionUUID)
            }
          )
        } else {
          setRefundableHistory(undefined)
        }
      }

      onOpenDetailsOutboundTransaction()
    }
  }

  if (isLoading || isLoadingWithFilters || isFetching) return <Skeleton />

  return (
    <>
      <Table
        size="sm"
        minWidth="100%"
        textType="initial"
        columns={columns}
        list={transactions}
        isPaginated={isPaginated}
        currentPage={currentPage}
        onClickRow={handleOnClickRow}
        handlePrevPage={() => handlePageChange(currentPage - 1)}
        handleNextPage={() => handlePageChange(currentPage + 1)}
        totalPages={walletTransactionsPagination?.totalPages}
        totalResults={walletTransactionsPagination?.totalResults}
        handleOnChangePage={pageNumber => handlePageChange(pageNumber)}
      />

      <TransactionDetailsModal
        isOutboundTransaction
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        originalNSU={originalTransactionNSU}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <TransactionDetailsModal
        isOutboundTransaction
        isOpen={isOpenOutboundTransaction}
        onClose={onCloseOutboundTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        originalNSU={originalTransactionNSU}
      />

      <CreditReversal
        isOpen={isOpenCreditReversal}
        onClose={onCloseCreditReversal}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        onSave={() => {
          refreshWalletList()
        }}
      />
    </>
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
