import { Flex } from '@chakra-ui/react'
import { useAuth } from 'contexts/authContext'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletTransationTypes } from 'models/WalletTransaction'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  formatDateToBrazilianFormat,
  formatDateToFilter
} from 'utils/dateUtils'

import Calendar, { DateRange } from './Calendar'
import ExportButton from './ExportButton'

export type FilterTransactionsProps = {
  type?: 'transactions' | 'exits' | 'appetizer'
  exportType?: 'transactions' | 'exits' | 'appetizer'
  excludeType?: WalletTransationTypes
  filterByAllTransactions?: boolean
  currentTab?: string
}

const FilterTransactions = ({
  exportType,
  type,
  excludeType,
  filterByAllTransactions = false,
  currentTab
}: FilterTransactionsProps) => {
  const { removeStorageItem, setStorageItem, getStorageItem } =
    useLocalStorage()

  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate, endDate] = dateRange
  const [calendarInputValue, setCalendarInputValue] = useState<string | null>(
    getStorageItem('datesToFilter') || ''
  )
  const [closeCalendarOnClick, setCloseCalendarOnClick] = useState(false)
  const { userLevel } = useAuth()

  const isAttendant3c = userLevel === 'Atendimento-3C'
  const isAttendantB2M = userLevel === 'Atendimento B2M'

  const { getTransactiosByFilter, getAllTransactions, currentFilters } =
    useWalletTransaction()

  const safeFilters = useMemo(() => ({ ...currentFilters }), [currentFilters])

  useEffect(() => {
    setDateRange([null, null])
    setCalendarInputValue('')
    removeStorageItem('datesToFilter')
  }, [currentTab])

  const handleRemoveFilter = useCallback(() => {
    const updatedFilters = { ...safeFilters }
    delete updatedFilters.dtStart
    delete updatedFilters.dtEnd

    removeStorageItem('datesToFilter')
    setDateRange([null, null])
    setCalendarInputValue('')

    if (filterByAllTransactions) {
      return getAllTransactions(updatedFilters)
    }

    getTransactiosByFilter(updatedFilters)
  }, [
    filterByAllTransactions,
    safeFilters,
    getAllTransactions,
    getTransactiosByFilter
  ])

  const handleOnChangeDate = useCallback(() => {
    const effectiveStartDate = startDate || new Date()
    const effectiveEndDate = endDate || startDate

    const dtStart = formatDateToFilter(effectiveStartDate)
    const dtEnd = formatDateToFilter(effectiveEndDate)

    const startDateBrazil = startDate
      ? formatDateToBrazilianFormat(startDate.toISOString())
      : formatDateToBrazilianFormat(new Date().toISOString())
    const endDateBrazil = formatDateToBrazilianFormat(
      effectiveEndDate!.toISOString()
    )

    const datesToStore =
      startDateBrazil === endDateBrazil
        ? startDateBrazil
        : `${startDateBrazil} - ${endDateBrazil}`
    setStorageItem('datesToFilter', datesToStore)
    setCalendarInputValue(datesToStore)

    const filters = {
      ...safeFilters,
      dtStart,
      dtEnd
    }

    localStorage.setItem('currentFilters', JSON.stringify(filters))

    if (type === 'exits') {
      delete filters.type
      getTransactiosByFilter({ ...filters, operation: '-', excludeType })
    } else if (type === 'transactions') {
      setCloseCalendarOnClick(true)
      delete filters.operation
      getTransactiosByFilter({ ...filters, type: 'PTX' })
    } else if (type === 'appetizer') {
      delete filters.type
      getTransactiosByFilter({ ...filters, operation: '%2B' })
    } else {
      delete filters.type
      getAllTransactions(filters)
    }
  }, [
    startDate,
    endDate,
    type,
    safeFilters,
    getAllTransactions,
    getTransactiosByFilter,
    filterByAllTransactions,
    setStorageItem,
    setCalendarInputValue,
    setCloseCalendarOnClick
  ])

  const renderExportTransactionButton = useCallback(
    (dtStart: string | null, dtEnd: string | null) => {
      if (isAttendant3c || isAttendantB2M) return null

      if (!exportType)
        return <ExportButton type="geral" dtStart={dtStart} dtEnd={dtEnd} />

      if (exportType === 'transactions')
        return (
          <ExportButton
            transactionType="PTX"
            type="transacoes"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'exits')
        return (
          <ExportButton
            operationType="-"
            type="saidas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )

      if (exportType === 'appetizer')
        return (
          <ExportButton
            operationType="%2B"
            type="entradas"
            dtStart={dtStart}
            dtEnd={dtEnd}
          />
        )
    },
    [startDate, endDate, exportType, isAttendant3c, isAttendantB2M]
  )

  const removeCachedDate = () => removeStorageItem('datesToFilter')

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedDate)
    return () => {
      window.removeEventListener('beforeunload', removeCachedDate)
    }
  }, [removeCachedDate])

  const renderCalendar = useCallback(
    () => (
      <Calendar
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        inputValue={calendarInputValue || ''}
        onChangeDate={handleOnChangeDate}
        onClear={handleRemoveFilter}
        closeOnClick={closeCalendarOnClick}
        h="40px"
        w="340px"
      />
    ),
    [
      calendarInputValue,
      closeCalendarOnClick,
      startDate,
      endDate,
      handleOnChangeDate,
      handleRemoveFilter
    ]
  )

  const startDateToExport =
    formatDateToFilter(startDate) || formatDateToFilter(new Date())
  const endDateToExport =
    formatDateToFilter(endDate) || formatDateToFilter(startDate)

  return (
    <Flex alignItems="center" justifyContent="flex-end" maxW="507px" gap="16px">
      <Flex>{renderCalendar()}</Flex>
      {renderExportTransactionButton(startDateToExport, endDateToExport)}
    </Flex>
  )
}

export default FilterTransactions
