import { Flex } from '@chakra-ui/react'
import Calendar, { DateRange } from 'components/ui/Calendar'
import React, { useCallback, useMemo, useState } from 'react'
import {
  formatDateToBrazilianFormat,
  formatDateToFilter
} from 'utils/dateUtils'

const DateFilter = ({
  onApply,
  onClear
}: {
  onApply: (startDate: string, endDate: string) => void
  onClear: () => void
}) => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null])
  const [startDate, endDate] = dateRange

  const handleApplyDateFilter = useCallback(() => {
    if (!startDate) return

    const finalEndDate = endDate || startDate

    const formattedStartDate = formatDateToFilter(startDate)
    const formattedEndDate = formatDateToFilter(finalEndDate)

    const startDateBrazil = formatDateToBrazilianFormat(startDate.toISOString())
    const endDateBrazil = formatDateToBrazilianFormat(
      finalEndDate.toISOString()
    )

    const dateFilterValue = JSON.stringify({
      startDate: startDateBrazil,
      endDate: endDateBrazil
    })

    localStorage.setItem('accountHistoryFilters', dateFilterValue)
    onApply(String(formattedStartDate), String(formattedEndDate))
  }, [startDate, endDate, onApply])

  const handleClearFilter = useCallback(() => {
    const filters = localStorage.getItem('accountHistoryFilters')
    if (filters) {
      const parsedFilters = JSON.parse(filters)
      delete parsedFilters.startDate
      delete parsedFilters.endDate
      localStorage.setItem(
        'accountHistoryFilters',
        JSON.stringify(parsedFilters)
      )
    } else {
      localStorage.removeItem('accountHistoryFilters')
    }

    setDateRange([null, null])

    onClear()
  }, [onClear])

  const formattedInputValue = useMemo(() => {
    if (!startDate) return ''
    if (!endDate || startDate === endDate) {
      return formatDateToBrazilianFormat(startDate.toISOString())
    }
    return `${formatDateToBrazilianFormat(
      startDate.toISOString()
    )} - ${formatDateToBrazilianFormat(endDate.toISOString())}`
  }, [startDate, endDate])

  return (
    <Flex justifyContent="flex-end" gap="16px" alignItems="center">
      <Calendar
        maxDate={new Date()}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        inputValue={formattedInputValue}
        onChangeDate={handleApplyDateFilter}
        onClear={handleClearFilter}
        closeOnClick={true}
      />
    </Flex>
  )
}

export default DateFilter
