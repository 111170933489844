import { Flex } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export default function WrapperPresentational({ children }: PropsWithChildren) {
  return (
    <Flex
      position="absolute"
      left="0"
      right="0"
      top="0"
      bottom="0"
      bg="rgba(0,0,0, 25%)"
      zIndex={99}
      alignItems="flex-start"
      justifyContent="center"
      pt={200}
    >
      <Flex flexDir="column" w="100%" maxW="660px" bg="white">
        {children}
      </Flex>
    </Flex>
  )
}
