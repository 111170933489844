import { Box, Button } from '@chakra-ui/react'
import React, { useState } from 'react'

interface TabComponentProps {
  tabs: string[]
  onTabSelected: (filters: Record<string, any>) => void
  tabFilters: Record<string, Record<string, any>>
}

const TabComponent = ({
  tabs,
  tabFilters,
  onTabSelected
}: TabComponentProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    const filters = tabFilters[tab] || {}
    onTabSelected({ tab, ...filters })
  }

  return (
    <Box
      bg="transparent"
      border="1px solid #0F266D"
      borderRadius="16px"
      fontFamily="TTTravels"
    >
      {tabs.map(tab => (
        <Button
          key={tab}
          onClick={() => handleTabClick(tab)}
          p="8px"
          height="32px"
          bg={activeTab === tab ? '#0F266D' : 'transparent'}
          color={activeTab === tab ? 'white' : '#0F2365'}
          _hover={{ bg: '#DEF3FB', color: 'white' }}
          letterSpacing="-0.8px"
          borderRadius="16px"
          fontSize="13px"
          fontWeight="600"
        >
          {tab}
        </Button>
      ))}
    </Box>
  )
}

export default TabComponent
