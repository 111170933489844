import { Center, Flex, Text } from '@chakra-ui/react'
import { useAlert } from 'hooks/useAlert'
import {
  WalletPriority,
  WalletPriorityToLayout
} from 'modules/wallet/domain/entities/WalletPriority'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import {
  renderWalletIcon,
  renderWalletBg,
  WalletIcon
} from 'templates/consumerDetails/consumerWallets/walletCard'
import { capitalizeSentence } from 'utils/stringUtils'

import ActionButtons from './actionsButton'
import Header from './header'

type EditWalletsPriorityProps = {
  walletList: WalletPriorityToLayout[]
}

const EditWalletsPriority = ({ walletList }: EditWalletsPriorityProps) => {
  const { alert } = useAlert()

  const [list, setList] = useState<WalletPriorityToLayout[]>(walletList)
  const [droppedItemIds, setDroppedItemIds] = useState<Array<string>>([])
  const { isLoadingUpdatePriorities, updatePriorities, setEditPriority } =
    useWallet()

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return

    const newList = reorder(list, result.source.index, result.destination.index)
    setList(newList)
    setDroppedItemIds(prevIds => [...prevIds, result.draggableId])
  }

  const reorder = (
    list: WalletPriority[],
    startIndex: number,
    endIndex: number
  ): any => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onSave = () => {
    const payloadList = list.map((item, index) => {
      return { id: item.id, priority: index + 1 }
    })

    updatePriorities(payloadList)
      .then(() => {
        setEditPriority(false)
        alert({
          id: 'walletPrioritySuccess',
          title: 'As prioridades foram atualizadas com sucesso',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        return alert({
          id: 'walletPriorityError',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente',
          status: 'error'
        })
      })
  }

  return (
    <Flex flexDir="column" gap="16px" w="100%">
      <Header
        buttons={
          <ActionButtons
            onSave={onSave}
            isLoadingSave={isLoadingUpdatePriorities}
          />
        }
      />

      <Text
        fontSize="14px"
        fontWeight="700"
        letterSpacing="-0.7px"
        lineHeight="20.3px"
        color="#221C46"
        pt="16px"
      >
        Clique e arraste as carteiras para definir a prioridade entre elas. As
        prioridades definem a ordem na qual os valores serão debitados das
        carteiras.
      </Text>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <Flex
              gap="8px"
              flexDir="column"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list.map((item, index: number) => (
                <Draggable
                  disableInteractiveElementBlocking
                  key={item.id}
                  draggableId={item.id!.toString()}
                  index={index}
                >
                  {provided => (
                    <Flex
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      bg={'#FAF8FF'}
                      borderRadius="4px"
                      p="12px 24px"
                      alignItems="center"
                      gap="32px"
                      border={
                        droppedItemIds.includes(String(item.id))
                          ? '4px solid #1B51DC'
                          : '1px solid #E5E3EA'
                      }
                      _hover={{
                        bg: '#F0EEF5'
                      }}
                      _active={{
                        border: '4px solid #1B51DC',
                        bg: '#FAF8FF'
                      }}
                    >
                      <Text
                        fontSize="18px"
                        fontWeight="700"
                        letterSpacing="-0.9px"
                        lineHeight="21.6px"
                        w="130px"
                      >
                        Prioridade: {index + 1}
                      </Text>
                      <Flex alignItems="center" gap="8px">
                        <Center
                          w="32px"
                          h="32px"
                          borderRadius="8px"
                          bg={renderWalletBg(
                            capitalizeSentence(String(item.name)) as WalletIcon
                          )}
                        >
                          {renderWalletIcon(
                            capitalizeSentence(String(item.name)) as WalletIcon
                          )}
                        </Center>

                        <Text
                          fontSize="16px"
                          fontWeight="600"
                          letterSpacing="-0.8px"
                          lineHeight="24px"
                        >
                          {item.name}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
    </Flex>
  )
}

export default EditWalletsPriority
