import { Event } from 'contexts/accountHistoryContext'
import React from 'react'

import Table from './TablePresentation'

const TableContainer = ({
  events,
  lastPage,
  isLoading,
  currentPage,
  totalPages,
  totalResults,
  handlePageChange,
  handleOnChangePage
}: {
  events: Event[]
  isLoading: boolean
  currentPage: number
  totalPages: number
  totalResults?: number
  lastPage: boolean
  handlePageChange: (pageNumber: number) => void
  handleOnChangePage: (pageNumber: number) => void
}) => {
  const isPaginated =
    (events?.length >= 20 && Number(totalPages) > 1) || Boolean(lastPage)

  return (
    <Table
      events={events}
      isLoading={isLoading}
      currentPage={currentPage}
      totalPages={totalPages || 1}
      totalResults={totalResults}
      isPaginated={isPaginated}
      handlePrevPage={() => handlePageChange(currentPage - 1)}
      handleNextPage={() => handlePageChange(currentPage + 1)}
      handleOnChangePage={handleOnChangePage}
    />
  )
}

export default TableContainer
