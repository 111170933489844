import { Button, Text } from '@chakra-ui/react'
import React from 'react'

type SubmitButtonPresentationalProps = {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export default function SubmitButtonPresentational({
  title,
  isLoading,
  isDisabled,
  onClick
}: SubmitButtonPresentationalProps) {
  return (
    <Button
      h="48px"
      p="4px 8px"
      color="white"
      bg="#1B51DC"
      border="3px solid"
      borderRadius="none"
      borderColor="#1B51DC"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      _disabled={{
        bg: '#EFEFEF',
        color: '#B5B2BC',
        borderColor: '#EFEFEF',
        cursor: 'not-allowed'
      }}
      _focusVisible={{
        boxShadow: 'none !important',
        background: '#DEF3FB',
        color: 'pluxee.interactive.primary'
      }}
      _hover={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        background: !isDisabled && '#DEF3FB',
        borderColor: !isDisabled && 'transparent',
        color: !isDisabled && '#1B51DC'
      }}
      _active={{
        boxShadow: !isDisabled && '4px 4px 0px 0px #A9A7B6 !important',
        color: !isDisabled && 'standard.white',
        background: !isDisabled && '#0F266D'
      }}
    >
      <Text fontSize="14px" fontWeight="700">
        {title}
      </Text>
    </Button>
  )
}
