import { useLocalStorage } from 'hooks/useLocalStorage'
import { WalletStatus } from 'modules/wallet/domain/entities/Wallet'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import RenderButtonsPresentational from '../presentational/RenderButtonsPresentational'

export type RenderButtonsProps = {
  id: number
  name: string
  status: WalletStatus
}

export function RenderButtonsContainer({
  id,
  name,
  status
}: RenderButtonsProps) {
  const navigate = useNavigate()
  const { getWalletById } = useWallet()
  const { getStorageItem } = useLocalStorage()

  const selectedProduct = JSON.parse(
    getStorageItem('selectedProduct') as string
  )

  const navigateToEdit = () =>
    navigate(`/wallet/edit/${id}`, { state: selectedProduct })
  const refreshWallet = () => getWalletById({ walletId: id })
  const navigateToWallets = () => navigate('/wallet')

  return (
    <RenderButtonsPresentational
      id={id}
      name={name}
      status={status}
      navigateToEdit={navigateToEdit}
      refreshWallet={refreshWallet}
      navigateToWallets={navigateToWallets}
    />
  )
}

export default RenderButtonsContainer
