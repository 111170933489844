import { useOktaAuth } from '@okta/okta-react'
import ComposeProviders from 'components/ui/ComposeProviders'
import { CustomerProvider } from 'contexts/customerContext'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomizedWalletUpdateTemplate from 'templates/customizedWalletUpdate/updateForm'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const CustomizedWalletUpdate = () => {
  const navigate = useNavigate()
  const { getStorageItem } = useLocalStorage()
  const { authState, oktaAuth } = useOktaAuth()
  const { user, isLoadingProfile, isSuccessProfile, isBackoffice4c } =
    useProfile()
  const ignoreAuthOkta = getStorageItem('ignoreAuthOkta')

  useEffect(() => {
    if (ignoreAuthOkta === 'true') return
    if (!isLoadingProfile && isSuccessProfile) {
      const hasPermission = isBackoffice4c
      if (user && !authState?.isAuthenticated && !isDevSide())
        oktaAuth.signInWithRedirect()
      if (user && !hasPermission) navigate('/not-allowed')
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [
    isLoadingProfile,
    isSuccessProfile,
    isBackoffice4c,
    navigate,
    user,
    oktaAuth,
    ignoreAuthOkta
  ])

  const providers = [
    { Provider: ProfileProvider },
    { Provider: CustomerProvider }
  ]

  return (
    <ComposeProviders providers={providers}>
      <CustomizedWalletUpdateTemplate />
    </ComposeProviders>
  )
}

export default CustomizedWalletUpdate
