import { Button, Flex, Text } from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import React from 'react'

type PrimaryButtonPresentationalProps = {
  title: string
  onClick: () => void
}

export default function PrimaryButtonPresentational({
  title,
  onClick
}: PrimaryButtonPresentationalProps) {
  return (
    <Flex height="40px">
      <Flex
        as={Button}
        gap="12px"
        bg="#1B51DC"
        alignItems="center"
        cursor="pointer"
        borderRadius="0"
        color="standard.white"
        onClick={onClick}
        _hover={{
          boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
          background: '#DEF3FB',
          color: '#221C46'
        }}
        _active={{
          boxShadow: '2px 2px 0px 0px #A9A7B6 !important',
          color: 'standard.white',
          background: 'pluxee.cta.secondaryPressed'
        }}
        _focusVisible={{
          boxShadow: 'none !important',
          background: '#DEF3FB',
          color: '#221C46',
          border: '3px solid #1B51DC'
        }}
      >
        <Text fontWeight="700" fontSize="14px" letterSpacing="-0.7px">
          {title}
        </Text>
        <Icon name="add" />
      </Flex>
    </Flex>
  )
}
