import { Spinner } from '@chakra-ui/react'
import { useProfile } from 'contexts/profileContext'
import { Wallet } from 'modules/wallet/domain/entities/Wallet'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WalletDetailsTemplatePresentational from '../presentational/WalletDetailsTemplatePresentational'

export function WalletDetailsTemplateContainer() {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { isPayments } = useProfile()
  const { getWalletById, walletInfo, isLoadingWalletInfo } = useWallet()

  useEffect(() => {
    if (!id) {
      navigate('/wallet?wallet_id_not_found')
      return
    }
    getWalletById({ walletId: Number(id) })
  }, [id])

  if (isLoadingWalletInfo) return <Spinner />
  if (!walletInfo && !isLoadingWalletInfo) {
    return <></>
  }

  return (
    <WalletDetailsTemplatePresentational
      wallet={walletInfo ?? ({} as Wallet)}
      isShowActButtons={!!walletInfo?.status && !isPayments}
    />
  )
}

export default WalletDetailsTemplateContainer
