import { Pagination } from 'models/Pagination'
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { AccountService } from 'services/accountService'

export type Event = {
  id: number
  accountId: number
  event: string
  eventDate: string
  description: string
  card: Card | null
  accountWallet: AccountWallet | null
}

export type Card = {
  id: number
  ararasId: number | null
  cardNumber: string
  cardName: string | null
  cvv: string | null
  accountId: number
  consumerId: number
  expirationDate: string
  cancellationDate: string | null
  alterationDate: string
  cardReason: string | null
  productId: number
  status: string
  externalCardId: number
  virtual: boolean
}

export type AccountWallet = {
  accountWalletId: number
  accountId: number
  balance: number
  dailyLimitTx: number
  dailyLimitValue: number
  dailyLimitWithdrawTx: number | null
  dailyLimitWithdrawValue: number | null
  monthlyLimitWithdrawTx: number | null
  monthlyLimitWithdrawValue: number | null
  lastModified: string
  walletDetails: WalletDetails
  status: string
  statusReasonCode: string | null
  paused: boolean
}

export type WalletDetails = {
  id: number
  name: string
  productId: number
  priority: number
  allowWithdraw: boolean
  openWallet: boolean
  status: string
  dailyLimitTx: number
  dailyLimitValue: number
  model: string
  activation_dt: string | null
  inactivation_dt: string | null
  creation_dt: string | null
  allowTransferPat: boolean
  allowTransferAux: boolean
  dailyLimitWithdrawTx: number | null
  dailyLimitWithdrawValue: number | null
  monthlyLimitWithdrawTx: number | null
  monthlyLimitWithdrawValue: number | null
  withdrawFee: number | null
  isBacenRegulated: boolean
}

export type Page = {
  previousPage: number
  currentPage: number
  nextPage: number | null
  totalPages: number
  totalItems: number
  maxItemsPerPage: number
  totalItemsPage: number
  lastPage: boolean
}

export type AccountEventsResponse = {
  events: Event[]
  page: Page
}

export type ContextType = {
  events: Event[]
  eventsPagination?: Pagination
  isLoading: boolean
  fetchAccountHistory: (params: {
    accountId?: number
    startDate?: string
    endDate?: string
    filters?: {
      event?: string[]
      reason?: string
      onlyCards?: boolean
      onlyAccountWallets?: boolean
    }
    page?: number
  }) => Promise<AccountEventsResponse>
}

export const AccountHistoryContext = createContext({} as ContextType)

type AccountEventsProviderProps = {
  children: ReactNode
}

export const AccountsHistoryProvider = ({
  children
}: AccountEventsProviderProps) => {
  const [events, setEvents] = useState<Event[]>([])
  const [eventsPagination, setEventsPagination] = useState<Pagination>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchAccountHistory = useMutation(
    async ({
      accountId,
      startDate,
      endDate,
      filters,
      page
    }: {
      accountId?: number
      startDate?: string
      endDate?: string
      filters?: {
        event?: string[]
        reason?: string
        onlyCards?: boolean
        onlyAccountWallets?: boolean
      }
      page?: number
    }): Promise<AccountEventsResponse> => {
      setIsLoading(true)
      const response = await AccountService.getAccountHistory({
        accountId,
        startDate,
        endDate,
        filters: {
          ...filters
        },
        page
      })
      setIsLoading(false)
      return response.data
    },
    {
      onSuccess: data => {
        setEvents(data.events)
        setEventsPagination({
          currentPage: data.page.currentPage,
          totalPages: data.page.totalPages,
          totalResults: data.page.totalItems,
          lastPage: data.page.lastPage
        })
      },
      onError: () => {
        setEvents([])
        setEventsPagination(undefined)
      }
    }
  )

  return (
    <AccountHistoryContext.Provider
      value={{
        events,
        isLoading,
        eventsPagination,
        fetchAccountHistory: fetchAccountHistory.mutateAsync
      }}
    >
      {children}
    </AccountHistoryContext.Provider>
  )
}

export const useAccountHistory = () => useContext(AccountHistoryContext)
