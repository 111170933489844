import { Flex, Text as ChakraText } from '@chakra-ui/react'
import { NoData } from 'components/ui'
import Table, { CellProps } from 'components/ui/Table'
import { Event } from 'contexts/accountHistoryContext'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence } from 'utils/stringUtils'

const ResultsTable = ({
  events,
  isLoading,
  isPaginated,
  currentPage,
  totalPages,
  totalResults,
  handlePrevPage,
  handleNextPage,
  handleOnChangePage
}: {
  events: any[]
  isLoading: boolean
  isPaginated: boolean
  currentPage: number
  totalPages: number
  totalResults?: number
  handlePrevPage: () => void
  handleNextPage: () => void
  handleOnChangePage: (pageNumber: number) => void
}) => {
  if (isLoading && !events) return <Skeleton count={15} />
  if (!events && !isLoading) return <NoData />

  const columns: CellProps[] = [
    { title: 'Status', render: (data: Event) => <Text>{data.event}</Text> },
    {
      title: 'Cartão ou Carteira',
      render: (data: Event) => renderWalletOrCard(data)
    },
    {
      title: 'Data e Hora',
      render: (data: Event) => (
        <Text>{formatToDateAndTime(data.eventDate)}</Text>
      )
    },
    {
      title: 'Descrição',
      render: (data: Event) => (
        <Text>
          {data.event === 'Conta Criada'
            ? `Número da conta: ${data.accountId}`
            : capitalizeSentence(data.description)}
        </Text>
      )
    }
  ]

  const renderWalletOrCard = (data: Event) => {
    const accountWallet = data.accountWallet
    const card = data.card

    if (accountWallet && accountWallet.walletDetails) {
      return <Text>{`Cartão ${accountWallet.walletDetails.name}`}</Text>
    }

    if (card) {
      const cardType = card.virtual ? 'virtual' : 'físico'
      const lastFourDigits = card.cardNumber.slice(-4)
      return <Text>{`Cartão ${cardType} ••••${lastFourDigits}`}</Text>
    }

    return <Text>{''}</Text>
  }

  return (
    <Flex flexDir="column" gap="14px" w="100%">
      <Table
        textType="initial"
        minWidth="100%"
        size="sm"
        columns={columns}
        list={events}
        isPaginated={isPaginated}
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={totalResults}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleOnChangePage={handleOnChangePage}
      />
    </Flex>
  )
}

const Text = ({ children }: { children: React.ReactNode }) => (
  <ChakraText
    fontWeight="500"
    color="#221C46"
    fontSize="14px"
    letterSpacing="-0.8px"
  >
    {children}
  </ChakraText>
)

export default ResultsTable
