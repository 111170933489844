import { useState, useEffect } from 'react'

type UsePaginationProps = {
  initialPage: number
  externalPage?: number
}

export const usePagination = ({
  initialPage,
  externalPage
}: UsePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  useEffect(() => {
    if (externalPage !== undefined && externalPage !== currentPage) {
      setCurrentPage(externalPage)
    }
  }, [externalPage, currentPage])

  const handlePrevPage = () =>
    setCurrentPage(prevState => Math.max(prevState - 1, 0))
  const handleNextPage = () => setCurrentPage(prevState => prevState + 1)
  const handleOnChangePage = (pageNumber: number) => setCurrentPage(pageNumber)
  const resetPage = (pageNumber: number) => setCurrentPage(pageNumber)

  return {
    currentPage,
    handleNextPage,
    handlePrevPage,
    handleOnChangePage,
    resetPage
  }
}
