import { Button } from '@chakra-ui/react'
import { useProfile } from 'contexts/profileContext'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './actionsButton.module.css'
import CreateWalletButton from './createWalletButton'

const ActionButtons = ({
  onSave,
  isLoadingSave = false
}: {
  onSave?: () => void
  isLoadingSave?: boolean
}) => {
  const { selectedProduct, editPriority, setEditPriority } = useWallet()
  const navigate = useNavigate()

  const { isPayments } = useProfile()
  const isShowEditPriorityBtn = !editPriority && !isPayments

  return (
    <>
      {!isPayments && (
        <CreateWalletButton
          isDisabled={editPriority}
          onClick={() => navigate('/wallet/add', { state: selectedProduct })}
        />
      )}

      {isShowEditPriorityBtn && (
        <Button
          gap="12px"
          alignItems="center"
          borderRadius="0"
          bg="#1B51DC"
          color="white"
          fontSize="14px"
          fontWeight="700"
          lineHeight="20.3px"
          letterSpacing="-0.7px"
          className={styles.button}
          rightIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 22L14 21L2 9L9 2L21 14L22 22ZM18.3787 14.2072L14.2072 18.3787L4.82843 9L9 4.82843L18.3787 14.2072ZM19.2544 16.1599L16.1599 19.2544L19.6965 19.6965L19.2544 16.1599Z"
                fill="white"
              />
            </svg>
          }
          iconSpacing="0"
          _hover={{
            boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
            background: '#DEF3FB',
            color: '#221C46',
            'svg path': 'fill:#221C46'
          }}
          _active={{
            boxShadow: '2px 2px 0px 0px #A9A7B6 !important',
            color: 'standard.white',
            background: 'pluxee.cta.secondaryPressed'
          }}
          _focusVisible={{
            boxShadow: 'none !important',
            background: '#DEF3FB',
            color: '#221C46',
            border: '3px solid #1B51DC'
          }}
          onClick={() => setEditPriority(true)}
        >
          Editar prioridades
        </Button>
      )}

      {editPriority && (
        <>
          <Button
            onClick={onSave}
            isLoading={isLoadingSave}
            letterSpacing="-0.7px"
            gap="12px"
            bg="#1B51DC"
            alignItems="center"
            cursor="pointer"
            borderRadius="0"
            color="standard.white"
            fontSize="14px"
            fontWeight="700"
            lineHeight="20.3px"
            _hover={{
              boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
              background: '#DEF3FB',
              color: '#221C46'
            }}
            _active={{
              boxShadow: '2px 2px 0px 0px #A9A7B6 !important',
              color: 'standard.white',
              background: 'pluxee.cta.secondaryPressed'
            }}
            _focusVisible={{
              boxShadow: 'none !important',
              background: '#DEF3FB',
              color: '#221C46',
              border: '3px solid #1B51DC'
            }}
            _disabled={{
              bg: '#EFEFEF',
              color: '#B5B2BC',
              cursor: 'not-allowed'
            }}
          >
            Salvar alterações
          </Button>

          <Button
            onClick={() => setEditPriority(false)}
            border="3px solid #1B51DC"
            color="#1B51DC"
            fontSize="14px"
            fontWeight="700"
            lineHeight="20.3px"
            letterSpacing="-0.7px"
            bg="white"
            padding="8px 16px"
            borderRadius="0"
            _hover={{
              boxShadow: '4px 4px 0px 0px #CBCAD2 !important',
              background: '#DEF3FB',
              borderColor: '#1B51DC',
              color: '#1B51DC'
            }}
            _active={{
              boxShadow: '2px 2px 0px 0px #A9A7B6 !important',
              color: 'standard.white',
              background: '#0F266D',
              borderColor: '#0F266D'
            }}
            _focusVisible={{
              color: '#1B51DC',
              background: '#DEF3FB',
              border: '3px solid #1B51DC',
              outline: '3px solid #1B51DC'
            }}
          >
            Cancelar
          </Button>
        </>
      )}
    </>
  )
}

export default ActionButtons
