import { Flex, Spinner } from '@chakra-ui/react'
import { WalletCatalog } from 'components/ui'
import { Wallet } from 'modules/wallet/domain/entities/Wallet'
import React from 'react'

interface WalletsListPresentationalProps {
  header: React.ReactNode
  wallets: Wallet[]
  isLoading: boolean
}

export default function WalletsListPresentational({
  header,
  wallets,
  isLoading
}: WalletsListPresentationalProps) {
  if (isLoading) return <Spinner />

  return (
    <Flex flexDir="column" gap="32px">
      {header}
      <Flex gap="32px" flexWrap="wrap">
        {wallets?.map(wallet => (
          <WalletCatalog key={wallet.id} {...wallet} />
        ))}
      </Flex>
    </Flex>
  )
}
