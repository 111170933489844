import { ButtonProps } from 'modules/wallet/application/queries/ButtonProps'
import React from 'react'

import SecondaryButtonPresentational from '../presentational/SecondaryButtonPresentational'

export function SecondaryButtonContainer({ title, onClick }: ButtonProps) {
  return <SecondaryButtonPresentational title={title} onClick={onClick} />
}

export default SecondaryButtonContainer
