import { useLocalStorage } from 'hooks/useLocalStorage'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect, useState } from 'react'

import { WalletPriorityToLayout } from '../../../../domain/entities/WalletPriority'
import WalletTemplatePresentational from '../presentational/WalletTemplatePresentational'

export function WalletTemplateContainer() {
  const { removeStorageItem } = useLocalStorage()
  const { setEditPriority, selectedProduct, editPriority, wallets } =
    useWallet()

  const [showingWallets, setShowingWallets] = useState(false)

  const onPageBack = () => {
    removeStorageItem('selectedProduct')
    setEditPriority(false)
  }

  const showWalletList = Boolean(
    selectedProduct?.productId && !editPriority && showingWallets
  )
  const showEditPriority = Boolean(
    selectedProduct?.productId && !showingWallets && editPriority
  )

  const createWalletPriority = (): WalletPriorityToLayout[] => {
    if (!wallets) return []
    return wallets
      .sort((a, b) => a.priority! - b.priority!)
      .map(wallet => ({
        id: wallet.id!,
        name: wallet.name!,
        priority: wallet.priority!
      }))
  }

  useEffect(() => {
    if (selectedProduct?.productId) setShowingWallets(true)
    setShowingWallets(!editPriority)
  }, [selectedProduct, editPriority])

  return (
    <WalletTemplatePresentational
      onPageBack={onPageBack}
      showWalletList={showWalletList}
      showEditPriority={showEditPriority}
      walletPriorityList={createWalletPriority()}
    />
  )
}

export default WalletTemplateContainer
