import {
  Button,
  Text,
  Stack,
  useDisclosure,
  Flex,
  Divider
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { Checkbox, Modal } from 'components/ui'
import React, { useState, useEffect } from 'react'

interface CardStatusFilterProps {
  onCardStatusChange: (status: string[]) => void
}

const CardStatusFilter = ({ onCardStatusChange }: CardStatusFilterProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedStatus, setSelectedStatus] = useState<string[]>(['ALL'])
  const [filter, setFilter] = useState<{ status: string[] }>({ status: [] })

  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.interactive.primary'
  }

  const statusMap = {
    'Ver todos os status de cartão': undefined,
    'Cartão Bloqueado e Desbloqueado': ['BLOCK_CARD', 'UNBLOCK_CARD'],
    'Cartão Cancelado': ['CANCEL_CARD'],
    'Senha Alterada': ['ALTER_PASSWORD'],
    'Cartão Criado (Físico e Virtual)': ['CREATED_CARD', 'CREATED_VIRTUAL_CARD']
  }

  const formattedStatusMap: { [key: string]: string } = {
    BLOCK_CARD: 'Cartão Bloqueado',
    UNBLOCK_CARD: 'Cartão Desbloqueado',
    CANCEL_CARD: 'Cartão Cancelado',
    ALTER_PASSWORD: 'Senha Alterada',
    CREATED_CARD: 'Cartão Criado (Físico e Virtual)',
    CREATED_VIRTUAL_CARD: 'Cartão Criado (Físico e Virtual)'
  }

  const items = Object.keys(statusMap).filter(
    status => status !== 'Ver todos os status de cartão'
  )

  const handleApply = () => {
    const formattedValues = selectedStatus.flatMap(
      status => statusMap[status as keyof typeof statusMap] || []
    )
    const finalValues = selectedStatus.includes('Ver todos os status de cartão')
      ? undefined
      : formattedValues.length
      ? formattedValues
      : undefined

    if (finalValues) {
      localStorage.setItem('selectedCardStatus', JSON.stringify(finalValues))
      onCardStatusChange(finalValues)
    } else {
      localStorage.removeItem('selectedCardStatus')
      onCardStatusChange([])
    }

    setFilter({ status: finalValues || [] })
    onClose()
  }

  const handleCheckboxChange = (status: string) => {
    if (status === 'Ver todos os status de cartão') {
      setSelectedStatus(['Ver todos os status de cartão'])
      setFilter({ status: [] })
      return
    }

    setSelectedStatus(prev => {
      const filtered = prev.filter(s => s !== 'Ver todos os status de cartão')

      if (filtered.includes(status)) {
        return filtered.filter(s => s !== status)
      } else {
        return [...filtered, status]
      }
    })
  }

  const modalFooter = (
    <Flex gap="16px">
      <Button
        bg="transparent"
        w="97px"
        h="48px"
        color="#1B51DC"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC',
          boxShadow: 'none !important'
        }}
        onClick={onClose}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
          Cancelar
        </Text>
      </Button>

      <Button
        w="82px"
        h="48px"
        bg="#1B51DC"
        color="white"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        _hover={{ ...defaultStyles, border: 'none' }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          boxShadow: 'none !important'
        }}
        onClick={handleApply}
      >
        <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
          Aplicar
        </Text>
      </Button>
    </Flex>
  )

  useEffect(() => {
    const checkboxGroupDiv =
      document.querySelectorAll<HTMLInputElement>('.pluxeeCheckbox')
    checkboxGroupDiv?.forEach(item => {
      const checkbox = item?.children[1] as HTMLInputElement
      const isSelected = selectedStatus.includes(item.innerText)

      checkbox.style.borderColor = isSelected ? '#1B51DC' : '#908C99'
      if (isSelected) {
        checkbox.removeAttribute('data-focus')
        checkbox.removeAttribute('data-focus-visible')
      }
    })
  }, [selectedStatus])

  const renderActiveFilters = () => {
    const uniqueStatuses = new Set(filter.status)
    const displayStatuses = Array.from(uniqueStatuses)

    const hasCreatedCard =
      displayStatuses.includes('CREATED_CARD') ||
      displayStatuses.includes('CREATED_VIRTUAL_CARD')
    const hasBlockedCard =
      displayStatuses.includes('BLOCK_CARD') ||
      displayStatuses.includes('UNBLOCK_CARD')

    const updateFilters = (newStatus: string[]) => {
      const updatedStatus = newStatus.length > 0 ? newStatus : undefined

      setFilter({ status: updatedStatus || [] })

      const updatedSelectedStatus = updatedStatus
        ? Object.keys(statusMap).filter(key =>
            updatedStatus.some(status =>
              (statusMap[key as keyof typeof statusMap] || []).includes(status)
            )
          )
        : []
      setSelectedStatus(updatedSelectedStatus)

      if (updatedStatus) {
        localStorage.setItem(
          'selectedCardStatus',
          JSON.stringify(updatedStatus)
        )
      } else {
        localStorage.removeItem('selectedCardStatus')
      }

      onCardStatusChange(updatedStatus || [])
    }

    return displayStatuses.length > 0 ? (
      <Flex gap="4" mt="16px">
        {hasCreatedCard && (
          <Button
            key="CREATED_CARD"
            h="32px"
            p="4px 8px"
            color="#221C46"
            bg="#E3E2FA"
            border="3px solid"
            borderRadius="none"
            borderColor="transparent"
            _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
            _active={{
              ...defaultStyles,
              background: 'pluxee.cta.secondaryPressed',
              color: 'standard.white',
              borderColor: 'pluxee.cta.secondaryPressed',
              boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
            }}
            _focusVisible={{
              ...defaultStyles,
              outline: '3px solid #1B51DC',
              boxShadow: 'none !important',
              color: '#221C46',
              bg: '#E3E2FA'
            }}
            gap="6px"
            onClick={() => {
              const newStatus = filter.status.filter(
                s => s !== 'CREATED_CARD' && s !== 'CREATED_VIRTUAL_CARD'
              )
              updateFilters(newStatus)
            }}
          >
            <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
              Cartão Criado (Físico e Virtual)
            </Text>
            <Icon name="close" size="md" color="#1B51DC" />
          </Button>
        )}
        {hasBlockedCard && (
          <Button
            key="BLOCK_CARD"
            h="32px"
            p="4px 8px"
            color="#221C46"
            bg="#E3E2FA"
            border="3px solid"
            borderRadius="none"
            borderColor="transparent"
            _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
            _active={{
              ...defaultStyles,
              background: 'pluxee.cta.secondaryPressed',
              color: 'standard.white',
              borderColor: 'pluxee.cta.secondaryPressed',
              boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
            }}
            _focusVisible={{
              ...defaultStyles,
              outline: '3px solid #1B51DC',
              boxShadow: 'none !important',
              color: '#221C46',
              bg: '#E3E2FA'
            }}
            gap="6px"
            onClick={() => {
              const newStatus = filter.status.filter(
                s => s !== 'BLOCK_CARD' && s !== 'UNBLOCK_CARD'
              )
              updateFilters(newStatus)
            }}
          >
            <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
              Cartão Bloqueado e Desbloqueado
            </Text>
            <Icon name="close" size="md" color="#1B51DC" />
          </Button>
        )}
        {displayStatuses.map(status => {
          if (
            status === 'CREATED_CARD' ||
            status === 'CREATED_VIRTUAL_CARD' ||
            status === 'BLOCK_CARD' ||
            status === 'UNBLOCK_CARD'
          ) {
            return null
          }
          return (
            <Button
              key={status}
              h="32px"
              p="4px 8px"
              color="#221C46"
              bg="#E3E2FA"
              border="3px solid"
              borderRadius="none"
              borderColor="transparent"
              _hover={{ ...defaultStyles, bg: '#878DFA', color: 'white' }}
              _active={{
                ...defaultStyles,
                background: 'pluxee.cta.secondaryPressed',
                color: 'standard.white',
                borderColor: 'pluxee.cta.secondaryPressed',
                boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
              }}
              _focusVisible={{
                ...defaultStyles,
                outline: '3px solid #1B51DC',
                boxShadow: 'none !important',
                color: '#221C46',
                bg: '#E3E2FA'
              }}
              gap="6px"
              onClick={() => {
                const newStatus = filter.status.filter(s => s !== status)
                updateFilters(newStatus)
              }}
            >
              <Text fontSize="14px" fontWeight="700" letterSpacing="-0.8px">
                {formattedStatusMap[status] || status}
              </Text>
              <Icon name="close" size="md" color="#1B51DC" />
            </Button>
          )
        })}
      </Flex>
    ) : null
  }

  return (
    <>
      <Button
        bg="transparent"
        maxW="212px"
        h="32px"
        color="#1B51DC"
        border="3px solid"
        borderRadius="none"
        borderColor="#1B51DC"
        rightIcon={<Icon name="filter_list" size="xs" fontWeight="700" />}
        _hover={{ ...defaultStyles }}
        _active={{
          ...defaultStyles,
          background: 'pluxee.cta.secondaryPressed',
          color: 'standard.white',
          borderColor: 'pluxee.cta.secondaryPressed',
          boxShadow: '2px 2px 0px 0px rgba(34,28,70,0.2) !important'
        }}
        _focusVisible={{
          ...defaultStyles,
          outline: '3px solid #1B51DC',
          boxShadow: 'none !important'
        }}
        onClick={onOpen}
      >
        <Text fontSize="12px" fontWeight="700" letterSpacing="-0.8px">
          Filtrar por status de cartão
        </Text>
      </Button>

      <Modal
        heading="Filtrar"
        footer={modalFooter}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        hideLine
        titleSpacingToBottom="0px"
      >
        <Stack spacing="xxxs">
          <Checkbox
            checked={selectedStatus.includes('Ver todos os status de cartão')}
            title={'Ver todos os status de cartão'}
            onChange={() =>
              handleCheckboxChange('Ver todos os status de cartão')
            }
          />
          <Divider />
          {items.map(item => (
            <Checkbox
              key={`Checkbox - ${item}`}
              checked={selectedStatus.includes(item)}
              title={item}
              onChange={() => handleCheckboxChange(item)}
            />
          ))}
        </Stack>
      </Modal>
      {renderActiveFilters()}
    </>
  )
}

export default CardStatusFilter
