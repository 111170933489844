import {
  CreditInTrustTypes,
  CreditReversalTypes,
  ReverseTransactionTypes
} from 'contexts/walletTransactionContext'
import { GetRefundableHistory } from 'models/request/GetRefundableHistory'
import { GetWalletsByConsumer } from 'models/request/GetWalletByConsumer'
import type { WalletTransationTypes } from 'models/WalletTransaction'
import { formatDateToFilter } from 'utils/dateUtils'

import { consumerApi } from './instances/consumerApi'

type RequiredFields = {
  accountId: number
  walletId: number
}

export type ReverseTransactionApiCall = RequiredFields & ReverseTransactionTypes
export type CreditInTrustApiCall = RequiredFields & CreditInTrustTypes
export type CreditReversalApiCall = RequiredFields & CreditReversalTypes

export type TransactionsFilters = {
  walletId?: number
  accountId?: number
  page?: number
  operation?: '%2B' | '-'
  type?: WalletTransationTypes
  dtStart?: string | null
  dtEnd?: string | null
  excludeType?: WalletTransationTypes
}

export type AllTransactions = Pick<
  TransactionsFilters,
  'accountId' | 'dtStart' | 'dtEnd' | 'page'
>

export const AccountWalletService = {
  getByAccountId: ({ accountId, customerId }: GetWalletsByConsumer) => {
    return consumerApi.get(`wallets/${accountId}/customer/${customerId}`, {
      data: null
    })
  },
  provisionalCredit({
    accountId,
    walletId,
    occorence_code,
    reason,
    value,
    authorization_code,
    uuid
  }: CreditInTrustApiCall) {
    return consumerApi.post(
      `/accounts/${accountId}/wallets/${walletId}/provisional-credit`,
      {
        occorence_code,
        reason,
        value: Number(value),
        authorization_code,
        uuid
      },
      {
        data: null
      }
    )
  },
  creditInTrust({
    accountId,
    walletId,
    occorence_code,
    reason,
    value,
    uuid
  }: CreditInTrustApiCall) {
    return consumerApi.post(
      `/accounts/${accountId}/wallets/${walletId}/reverse-credit`,
      {
        accountId,
        walletId,
        occorence_code,
        reason,
        value: Number(value),
        uuid
      },
      {
        data: null
      }
    )
  },
  creditReversal({
    accountId,
    walletId,
    occorence_code,
    reason,
    value,
    authorization_code,
    uuid
  }: CreditInTrustApiCall) {
    return consumerApi.post(
      `/accounts/${accountId}/wallets/${walletId}/provisional-credit-reverse`,
      {
        accountId,
        walletId,
        occorence_code,
        reason,
        value: Number(value),
        authorization_code,
        uuid
      },
      {
        data: null
      }
    )
  },
  reverseTransaction({
    accountId,
    walletId,
    occurrence,
    reason,
    value,
    customerId
  }: ReverseTransactionApiCall) {
    return consumerApi.post(
      `accounts/${accountId}/wallets/${walletId}/refund`,
      { occurrence, reason, value: Number(value), customerId },
      {
        data: null
      }
    )
  },
  getTransactions: ({
    walletId,
    accountId,
    operation,
    type,
    page,
    dtStart,
    dtEnd,
    excludeType
  }: TransactionsFilters) => {
    const todayDate = new Date()

    const defaultDtStart = formatDateToFilter(
      new Date(todayDate.setMonth(todayDate.getMonth() - 60))
    )

    const defaultDtEnd = formatDateToFilter(new Date())

    const transactionTypeFilter = type ? `&type=${type}` : ''
    const operationTypeFilter = operation ? `&operation=${operation}` : ''
    const pageFilter = page ? `&page=${page}` : ''
    const excludeTypeFilter = excludeType ? `&excludeType=${excludeType}` : ''

    const dateStart = dtStart
      ? `dtStart=${dtStart}`
      : `dtStart=${defaultDtStart}`
    const dateEnd = dtEnd ? `dtEnd=${dtEnd}` : `dtEnd=${defaultDtEnd}`

    const endpoint = `/accounts/${accountId}/wallets/${walletId}/transactions?${dateStart}&${dateEnd}${pageFilter}${excludeTypeFilter}${transactionTypeFilter}${operationTypeFilter}&size=20`

    return consumerApi.get(endpoint, { data: null })
  },
  getAllTransactions: ({
    accountId,
    page,
    dtStart,
    dtEnd
  }: AllTransactions) => {
    const todayDate = new Date()

    const defaultDtStart = formatDateToFilter(
      new Date(todayDate.setMonth(todayDate.getMonth() - 60))
    )

    const defaultDtEnd = formatDateToFilter(new Date())
    const pageFilter = page ? `&page=${page}` : ''

    const dateStart = dtStart
      ? `dtStart=${dtStart}`
      : `dtStart=${defaultDtStart}`
    const dateEnd = dtEnd ? `dtEnd=${dtEnd}` : `dtEnd=${defaultDtEnd}`

    const endpoint = `/accounts/${accountId}/transactions?${dateStart}&${dateEnd}${pageFilter}&size=20`

    return consumerApi.get(endpoint, { data: null })
  },
  getRefundableHistory: ({ transactionUUID }: GetRefundableHistory) => {
    return consumerApi.get(
      `/accounts/transaction/${transactionUUID}/refundable-history`
    )
  }
}
