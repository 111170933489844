import { ButtonProps } from 'modules/wallet/application/queries/ButtonProps'
import React from 'react'

import PrimaryButtonPresentational from '../presentational/PrimaryButtonPresentational'

export function PrimaryButtonContainer({ title, onClick }: ButtonProps) {
  return <PrimaryButtonPresentational title={title} onClick={onClick} />
}

export default PrimaryButtonContainer
