import { Flex, Text, Center, Image } from 'components/atoms'
import { CustomerWallet } from 'models/CustomerWallet'
import { CustomerWalletPayload } from 'modules/customer/presentation/contexts/CustomerContext'
import React from 'react'

import SkeletonWallet from './SkeletonWallet'
import { WalletCard } from './WalletCard'

interface CustomerWalletsPresentationalProps {
  wallets: CustomerWalletPayload[]
  isLoadingWallets: boolean
}

const formatProductName = (name: string) => {
  if (name === 'New 3C Gift') return 'Produto 3C'
  if (name === 'New 4C') return 'Produto 4C'
  return name
}

const CustomerWalletsPresentational = ({
  wallets,
  isLoadingWallets
}: CustomerWalletsPresentationalProps) => {
  if (isLoadingWallets) return <SkeletonWallet />

  return (
    <Flex flexDir="column" gap="24px" fontWeight="700">
      <Text letterSpacing={'-0.8px'} color="#221C46" fontSize="24px">
        Limites em carteira
      </Text>
      {wallets.map((walletGroup: any) => (
        <Flex key={walletGroup.product.name} flexDir="column" gap="24px">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            gap="8px"
            pl="16px"
          >
            <Center w="32px" h="32px" bg="#c7fcca" borderRadius="6px">
              <Image src="/icons/pluxee/default.svg" alt="Wallet Icon" />
            </Center>
            <Text
              fontWeight="700"
              letterSpacing={'-0.8px'}
              color="#221C46"
              fontSize="18px"
            >
              {formatProductName(walletGroup.product.name)}
            </Text>
          </Flex>

          <Flex flexWrap="wrap" gap="32px" alignItems="center">
            {walletGroup.customerWallets.map((item: CustomerWallet) => (
              <WalletCard key={`customerWallet-${item.id}`} {...item} />
            ))}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default CustomerWalletsPresentational
