import { Flex, useDisclosure } from '@chakra-ui/react'
import { WalletStatus } from 'modules/wallet/domain/entities/Wallet'
import React, { PropsWithChildren } from 'react'

import ActiveWalletContainer from '../../ActiveWallet/container/ActiveWalletContainer'
import DeleteWalletContainer from '../../DeleteWallet/container/DeleteWalletContainer'
import InactiveWalletContainer from '../../InactiveWallet/container/InactiveWalletContainer'
import PrimaryButtonContainer from '../../PrimaryButton/container/PrimaryButtonContainer'
import SecondaryButtonContainer from '../../SecondaryButton/container/SecondaryButtonContainer'

type RenderButtonsPresentationalProps = {
  id: number
  name: string
  status: WalletStatus
  navigateToEdit: () => void
  refreshWallet: () => void
  navigateToWallets: () => void
}

export default function RenderButtonsPresentational({
  id,
  name,
  status,
  navigateToEdit,
  refreshWallet,
  navigateToWallets
}: RenderButtonsPresentationalProps) {
  if (status === 'INACTIVE') {
    return (
      <InactiveFlow
        walletId={id}
        walletName={name}
        onComplete={refreshWallet}
        onClick={navigateToEdit}
      />
    )
  }

  if (status === 'CREATED') {
    return <CreatedFlow onClick={navigateToEdit} />
  }

  if (status === 'DRAFT') {
    return (
      <DraftFlow
        walletId={id}
        walletName={name}
        onDelete={navigateToWallets}
        onComplete={refreshWallet}
        onClick={navigateToEdit}
      />
    )
  }

  return (
    <ActiveFlow
      walletId={id}
      walletName={name}
      onComplete={refreshWallet}
      onClick={navigateToEdit}
    />
  )
}

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex gap="8px">{children}</Flex>
)

type ButtonsFlowProps = {
  onClick: () => void
  walletName: string
  walletId: number
  onComplete: () => void
}

const ActiveFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete
}: ButtonsFlowProps) => {
  const {
    isOpen: isOpenInactiveModal,
    onClose: onCloseInactiveModal,
    onOpen: onOpenInactiveModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButtonContainer title="Editar carteira" onClick={onClick} />
        <SecondaryButtonContainer
          title="Inativar carteira"
          onClick={onOpenInactiveModal}
        />
      </Wrapper>

      <InactiveWalletContainer
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseInactiveModal}
        isOpen={isOpenInactiveModal}
        onComplete={onComplete}
      />
    </>
  )
}

const InactiveFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete
}: ButtonsFlowProps) => {
  const {
    isOpen: isOpenActiveModal,
    onClose: onCloseActiveModal,
    onOpen: onOpenActiveModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButtonContainer title="Editar carteira" onClick={onClick} />
        <SecondaryButtonContainer
          title="Ativar carteira"
          onClick={onOpenActiveModal}
        />
      </Wrapper>

      <ActiveWalletContainer
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseActiveModal}
        isOpen={isOpenActiveModal}
        onComplete={onComplete}
      />
    </>
  )
}

const CreatedFlow = ({ onClick }: Pick<ButtonsFlowProps, 'onClick'>) => (
  <Wrapper>
    <PrimaryButtonContainer title="Editar carteira" onClick={onClick} />
  </Wrapper>
)

const DraftFlow = ({
  walletName,
  walletId,
  onClick,
  onComplete,
  onDelete
}: ButtonsFlowProps & { onDelete: () => void }) => {
  const {
    isOpen: isOpenActiveModal,
    onClose: onCloseActiveModal,
    onOpen: onOpenActiveModal
  } = useDisclosure()

  const {
    isOpen: isOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <PrimaryButtonContainer title="Editar carteira" onClick={onClick} />
        <SecondaryButtonContainer
          title="Ativar carteira"
          onClick={onOpenActiveModal}
        />
        <SecondaryButtonContainer
          title="Excluir carteira"
          onClick={onOpenDeleteModal}
        />
      </Wrapper>

      <ActiveWalletContainer
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseActiveModal}
        isOpen={isOpenActiveModal}
        onComplete={onComplete}
      />

      <DeleteWalletContainer
        walletId={walletId}
        walletName={walletName}
        onClose={onCloseDeleteModal}
        isOpen={isOpenDeleteModal}
        onComplete={onDelete}
      />
    </>
  )
}
