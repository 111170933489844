import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import { Container, Layout, StatusLabel } from 'components/ui'
import { Wallet } from 'modules/wallet/domain/entities/Wallet'
import React from 'react'
import {
  renderWalletIcon,
  renderWalletBg,
  WalletIcon
} from 'templates/consumerDetails/consumerWallets/walletCard'
import { formatToBrl } from 'utils/currencyUtils'
import { formatProductId } from 'utils/productUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import { formatWalletModel } from 'utils/walletUtils'

import RenderButtonsContainer from '../../RenderButtons/container/RenderButtonsContainer'

type WalletDetailsTemplatePresentationalProps = {
  wallet: Wallet
  isShowActButtons: boolean
}

export default function WalletDetailsTemplatePresentational({
  wallet,
  isShowActButtons
}: WalletDetailsTemplatePresentationalProps) {
  const maxChar = 23
  const capitalizedWalletName = capitalizeSentence(
    limitString(wallet.name ?? '', maxChar)
  )
  const isAllowTransfer = wallet.allowTransferAux || wallet.allowTransferPat
  const isUnlimitedDailyWithdraw = wallet.dailyLimitWithdrawTx === 999999
  const isUnlimitedMonthlyWithdraw = wallet.monthlyLimitWithdrawTx === 999999
  const isUnlimitedDailyTransaction = wallet.dailyLimitTx === 999999

  return (
    <Layout isShowBackButton colorScheme="secondary" maxWidth="1126px">
      <Container>
        <Flex p="16px 40px" mt="16px">
          <Flex
            flexDir="column"
            maxW="1046px"
            bg="white"
            width="100%"
            p="40px"
            gap="24px"
            margin="0 auto"
            boxShadow="1px 1px 0px 0px rgba(34, 28, 70, 0.20)"
          >
            <Flex justifyContent="space-between">
              <Flex gap="24px" alignItems="center" pl="16px" pb="16px">
                <Center
                  w="40px"
                  h="40px"
                  borderRadius="4px"
                  bg={renderWalletBg(capitalizedWalletName as WalletIcon)}
                >
                  {renderWalletIcon(capitalizedWalletName as WalletIcon)}
                </Center>

                <Box>
                  <Text
                    color="#221C46"
                    fontSize="24px"
                    fontWeight="700"
                    letterSpacing="-1.2px"
                  >
                    {capitalizeSentence(wallet.name ?? '-')}
                  </Text>
                  {wallet.status && (
                    <Flex gap="8px">
                      <Text
                        color="#463F5F"
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="24px"
                        letterSpacing="-0.8px"
                      >
                        Status:
                      </Text>
                      <StatusLabel statusType={wallet.status} />
                    </Flex>
                  )}
                </Box>
              </Flex>

              {isShowActButtons && (
                <RenderButtonsContainer
                  id={wallet.id ?? 0}
                  status={wallet.status ?? 'INACTIVE'}
                  name={wallet.name ?? '-'}
                />
              )}
            </Flex>

            <Flex flexDir="column">
              <Text
                color="#221C46"
                fontSize="20px"
                fontWeight="700"
                lineHeight="32px"
                letterSpacing="-1px"
                pb="24px"
              >
                Detalhes da carteira
              </Text>
              <Flex
                w="100%"
                pt="24px"
                pb="48px"
                borderTop="1px solid #F0EEF5"
                borderBottom="1px solid #F0EEF5"
              >
                <Flex flexDir="column" w="50%" gap="8px">
                  <Text
                    color="#221C46"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="-0.8px"
                  >
                    Carteira
                  </Text>
                  <Detail label="Prioridade:" value={wallet.priority ?? '-'} />
                  {wallet.productId && (
                    <Detail
                      label="Produto:"
                      value={formatProductId(String(wallet.productId))}
                    />
                  )}
                  {wallet.model && (
                    <Detail
                      label="Modelo:"
                      value={formatWalletModel(wallet.model)}
                    />
                  )}
                </Flex>

                <Flex flexDir="column" w="50%" gap="8px">
                  <Text
                    color="#221C46"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="-0.8px"
                  >
                    Permissões
                  </Text>
                  <Detail
                    label="Carteira aberta?"
                    value={wallet.openWallet ? 'Sim' : 'Não'}
                  />
                  <Detail
                    label="Permite transferência?"
                    value={isAllowTransfer ? 'Sim' : 'Não'}
                  />
                  <Detail
                    label="Permite saque?"
                    value={wallet.allowWithdraw ? 'Sim' : 'Não'}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex flexDir="column" gap="8px">
              <Text
                color="#221C46"
                fontSize="16px"
                fontWeight="700"
                lineHeight="24px"
                letterSpacing="-0.8px"
              >
                Limites
              </Text>
              <LimitsBox title="Transações diárias">
                <LimitDetail
                  label="Limite diário total:"
                  value={formatToBrl(wallet.dailyLimitValue ?? 0)}
                />
                <LimitDetail
                  label="Quantidade de transações diárias:"
                  value={
                    isUnlimitedDailyTransaction
                      ? 'Ilimitado'
                      : `${wallet.dailyLimitTx ?? 0} transações`
                  }
                />
              </LimitsBox>

              {wallet.allowWithdraw && (
                <LimitsBox title="Saques">
                  <LimitDetail
                    label="Limite diário total:"
                    value={formatToBrl(wallet.dailyLimitWithdrawValue ?? 0)}
                  />
                  <LimitDetail
                    label="Quantidade de saques diários:"
                    value={
                      isUnlimitedDailyWithdraw
                        ? 'Ilimitado'
                        : `${wallet.dailyLimitWithdrawTx ?? 0} transações`
                    }
                  />
                  <LimitDetail
                    label="Taxa de saque:"
                    value={formatToBrl(wallet.withdrawFee ?? 0)}
                  />
                  <LimitDetail
                    label="Quantidade de saques mensais:"
                    value={
                      isUnlimitedMonthlyWithdraw
                        ? 'Ilimitado'
                        : `${wallet.monthlyLimitWithdrawTx ?? 0} transações`
                    }
                  />
                  <LimitDetail
                    label="Limite mensal total:"
                    value={formatToBrl(wallet.monthlyLimitWithdrawValue ?? 0)}
                  />
                </LimitsBox>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

const Detail = ({
  label,
  value
}: {
  label: string
  value: string | number | undefined
}) => (
  <Flex>
    <Text
      color="#221C46"
      fontSize="14px"
      fontWeight="700"
      lineHeight="20.3px"
      letterSpacing="-0.7px"
      w="280px"
    >
      {label}
    </Text>
    <Text
      color="#221C46"
      fontSize="14px"
      fontWeight="500"
      lineHeight="20.3px"
      letterSpacing="-0.7px"
    >
      {value ?? '-'}
    </Text>
  </Flex>
)

const LimitsBox = ({
  title,
  children
}: {
  title: string
  children: React.ReactNode
}) => (
  <Box bg="#FAF8FF" padding="16px">
    <Flex gap="8px" alignItems="center">
      <Center bg="#C7FCCA" w="32px" h="32px" p="4px" borderRadius="6px">
        <Image src="/img/walletDetails/plx-icon-payment.png" alt="Icon" />
      </Center>
      <Text
        color="#221C46"
        fontSize="14px"
        fontWeight="700"
        lineHeight="20.3px"
        letterSpacing="-0.7px"
      >
        {title}
      </Text>
    </Flex>
    <Flex pt="16px" flexDirection="column" gap="8px">
      {children}
    </Flex>
  </Box>
)

const LimitDetail = ({
  label,
  value
}: {
  label: string
  value: string | number | undefined
}) => (
  <Flex justifyContent="space-between">
    <Text
      color="#221C46"
      fontSize="14px"
      fontWeight="700"
      lineHeight="20.3px"
      letterSpacing="-0.7px"
    >
      {label}
    </Text>
    <Text
      color="#221C46"
      fontSize="14px"
      fontWeight="500"
      lineHeight="20.3px"
      letterSpacing="-0.7px"
    >
      {value ?? '-'}
    </Text>
  </Flex>
)
