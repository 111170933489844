import { Flex } from '@chakra-ui/react'
import { Container, Layout } from 'components/ui'
import { WalletPriorityToLayout } from 'modules/wallet/domain/entities/WalletPriority'
import React from 'react'
import { ActionButtons, Header } from 'templates/wallet/components'
import EditWalletsPriority from 'templates/wallet/components/editWalletsPriority'

import { ProductsContainer } from '../../Products/container/ProductsContainer'
import { WalletsListContainer } from '../../WalletsList/container/WalletsListContainer'

interface WalletTemplatePresentationalProps {
  onPageBack: () => void
  showWalletList: boolean
  showEditPriority: boolean
  walletPriorityList: WalletPriorityToLayout[]
}

export default function WalletTemplatePresentational({
  onPageBack,
  showWalletList,
  showEditPriority,
  walletPriorityList
}: WalletTemplatePresentationalProps) {
  return (
    <Layout isShowBackButton onPageBack={onPageBack}>
      <Container>
        <Flex flexDir="column" bg="white" width="100%" p="40px" gap="40px">
          <ProductsContainer />
          {showWalletList && (
            <WalletsListContainer
              header={<Header buttons={<ActionButtons />} />}
            />
          )}
          {showEditPriority && (
            <EditWalletsPriority walletList={walletPriorityList} />
          )}
        </Flex>
      </Container>
    </Layout>
  )
}
