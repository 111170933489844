import { RestoreWalletOriginalValues } from 'models/request/RestoreWalletOriginalValues'
import { UpdateWalletToCustomValues } from 'models/request/UpdateWalletToCustomValues'
import { GetCustomizedClientsProps } from 'modules/customer/application/queries/GetCustomizedClientsProps'
import { CustomizedClient } from 'modules/customer/domain/entities/CustomizedClient'
import { ICustomerRepository } from 'modules/customer/domain/repositories/ICustomerRepository'
import { CustomerWalletPayload } from 'modules/customer/presentation/contexts/CustomerContext'

import { customerApi } from '../api/CustomerApi'
import { GetByDocumentResponse } from '../dto/GetByDocumentResponse'

export class CustomerRepository implements ICustomerRepository {
  async getByDocument(document: string): Promise<GetByDocumentResponse> {
    try {
      const response = await customerApi.get(`/customers/${document}`)
      return response.data || null
    } catch (error) {
      console.error('Error fetching customer by document:', error)
      throw error
    }
  }

  async getWalletsByCustomerId(
    customerId: string
  ): Promise<CustomerWalletPayload[]> {
    try {
      const response = await customerApi.get(`/customers/${customerId}/wallets`)
      return response.data || []
    } catch (error) {
      console.error('Error fetching wallets by customer ID:', error)
      throw error
    }
  }

  async getCustomizedClients(
    params: GetCustomizedClientsProps
  ): Promise<CustomizedClient[]> {
    try {
      const response = await customerApi.get('/customers', {
        params: {
          size: params.size,
          page: params.page,
          customValues: params.customValues
        }
      })
      return response.data?.customers || []
    } catch (error) {
      console.error('Error fetching customized clients:', error)
      throw error
    }
  }

  async updateWalletToCustomValues(
    customValues: UpdateWalletToCustomValues
  ): Promise<void> {
    try {
      const body = [{ ...customValues }]
      await customerApi.put(
        `/customerWallets/${customValues.customerId}/wallets`,
        body
      )
    } catch (error) {
      console.error('Error updating wallet to custom values:', error)
      throw error
    }
  }

  async restoreWalletOriginalValues(
    walletInfo: RestoreWalletOriginalValues
  ): Promise<void> {
    try {
      const body = [{ ...walletInfo }]
      await customerApi.put(
        `/customerWallets/${walletInfo.customerId}/wallets`,
        body
      )
    } catch (error) {
      console.error('Error restoring wallet original values:', error)
      throw error
    }
  }
}
