import { AxiosError } from 'axios'
import {
  useMutationActive,
  useMutationCreate,
  useMutationGetAllWallets,
  useMutationGetById,
  useMutationInactive,
  useMutationRemove,
  useMutationUpdate,
  useMutationUpdatePriorities
} from 'hooks/queries/walletQueries'
import { ErrorResponse } from 'models/ErrorResponse'
import { Product } from 'models/Product'
import type {
  CreateAndEditWallet,
  Wallet,
  WalletStatus
} from 'modules/wallet/domain/entities/Wallet'
import { WalletPriority } from 'modules/wallet/domain/entities/WalletPriority'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

type FilterType = {
  name: string
  status: WalletStatus | 'ALL'
}

export type ContextType = {
  wallets: Wallet[]
  isLoading: boolean

  walletInfo?: Wallet

  selectedProduct: Product | null
  filter: FilterType

  isLoadingCreate: boolean
  isSuccessCreate: boolean
  errorCreate: AxiosError<ErrorResponse>

  isLoadingUpdate: boolean
  isSuccessUpdate: boolean
  errorUpdate: AxiosError<ErrorResponse>

  isLoadingActive: boolean
  isErrorActive: boolean
  isSuccessActive: boolean

  isLoadingRemove: boolean
  isErrorRemove: boolean
  isSuccessRemove: boolean

  isLoadingInactive: boolean
  isErrorInactive: boolean
  isSuccessInactive: boolean

  isRefetch: boolean

  isLoadingWalletInfo: boolean
  isErrorWalletInfo: boolean

  editPriority: boolean
  setEditPriority: (value: boolean) => void

  updatePriorities: (updatePriorities: WalletPriority[]) => Promise<void>
  isLoadingUpdatePriorities: boolean

  setFilter: (filter: FilterType) => void
  selectProduct: (product: Product) => void
  createWallet: (wallet: CreateAndEditWallet) => Promise<Wallet | ErrorResponse>
  updateWallet: (wallet: CreateAndEditWallet) => Promise<Wallet | ErrorResponse>
  activeWallet: (id: number) => Promise<void>
  inactiveWallet: (id: number) => Promise<void>
  removeWallet: (id: number) => Promise<void>
  setIsRefetch: (value: boolean) => void

  getWalletsByProductId: ({ productId }: { productId: number }) => Promise<void>
  getWalletById: ({ walletId }: { walletId: number }) => Promise<void>
}

export const Context = createContext({} as ContextType)

type WalletProviderProps = {
  children: ReactNode
}

export const WalletProvider = ({ children }: WalletProviderProps) => {
  const [wallets, setWallets] = useState<Wallet[]>([])
  const [walletInfo, setWalletInfo] = useState<Wallet>()

  const [filter, setFilter] = useState<FilterType>({ name: '', status: 'ALL' })
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [isRefetch, setIsRefetch] = useState(false)
  const [editPriority, setEditPriority] = useState(false)

  const mutateGetAll = useMutationGetAllWallets()
  const { data, isLoading, isError } = mutateGetAll

  const mutateGetById = useMutationGetById()
  const {
    data: dataWalletInfo,
    isLoading: isLoadingWalletInfo,
    isError: isErrorWalletInfo
  } = mutateGetById

  const mutateCreate = useMutationCreate()
  const {
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    error: errorCreate
  } = mutateCreate

  const mutateUpdate = useMutationUpdate()
  const {
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    error: errorUpdate
  } = mutateUpdate

  const mutateActive = useMutationActive()
  const {
    isLoading: isLoadingActive,
    isSuccess: isSuccessActive,
    isError: isErrorActive
  } = mutateActive

  const mutateInactive = useMutationInactive()
  const {
    isLoading: isLoadingInactive,
    isSuccess: isSuccessInactive,
    isError: isErrorInactive
  } = mutateInactive

  const mutateRemove = useMutationRemove()
  const {
    isLoading: isLoadingRemove,
    isSuccess: isSuccessRemove,
    isError: isErrorRemove
  } = mutateRemove

  const mutateUpdatePriorities = useMutationUpdatePriorities()
  const { isLoading: isLoadingUpdatePriorities } = mutateUpdatePriorities

  const selectProduct = (product: Product) => {
    setSelectedProduct(product)
  }

  const createWallet = async (wallet: CreateAndEditWallet) => {
    return await mutateCreate.mutateAsync(wallet)
  }

  const updateWallet = async (wallet: CreateAndEditWallet) => {
    return await mutateUpdate.mutateAsync(wallet)
  }

  const activeWallet = async (id: number) => {
    await mutateActive.mutateAsync(id)
  }

  const inactiveWallet = async (id: number) => {
    await mutateInactive.mutateAsync(id)
  }

  const removeWallet = async (id: number) => {
    await mutateRemove.mutateAsync(id)
  }

  const updatePriorities = async (newPriorities: WalletPriority[]) => {
    return await mutateUpdatePriorities.mutateAsync(newPriorities)
  }

  const getWalletsByProductId = useCallback(
    async ({ productId }: { productId: number }) => {
      await mutateGetAll.mutateAsync({ productId })
    },
    [mutateGetAll]
  )

  const getWalletById = useCallback(
    async ({ walletId }: { walletId: number }) => {
      await mutateGetById.mutateAsync({ walletId })
    },
    [mutateGetById]
  )

  useEffect(() => {
    if (data) {
      const wallets = data as Wallet[]
      const orderedWallets = wallets.sort((a, b) => a.priority! - b.priority!)
      setWallets(orderedWallets)
    }
  }, [data])

  useEffect(() => {
    if (isError) setWallets([])
  }, [isError])

  useEffect(() => {
    if (dataWalletInfo) setWalletInfo(dataWalletInfo)
  }, [dataWalletInfo])

  useEffect(() => {
    if (isErrorWalletInfo) setWalletInfo(undefined)
  }, [isErrorWalletInfo])

  return (
    <Context.Provider
      value={{
        filter,
        isLoadingCreate,
        isSuccessCreate,
        isLoadingUpdate,
        isSuccessUpdate,
        selectedProduct,
        isLoadingActive,
        isErrorActive,
        isSuccessActive,
        isLoadingRemove,
        isErrorRemove,
        isSuccessRemove,
        isLoadingInactive,
        isErrorInactive,
        isSuccessInactive,
        setFilter,
        selectProduct,
        createWallet,
        updateWallet,
        activeWallet,
        inactiveWallet,
        removeWallet,
        isRefetch,
        setIsRefetch,
        getWalletsByProductId,
        wallets,
        isLoading,
        isLoadingWalletInfo,
        isErrorWalletInfo,
        getWalletById,
        walletInfo,
        errorCreate: errorCreate as AxiosError<ErrorResponse>,
        errorUpdate: errorUpdate as AxiosError<ErrorResponse>,
        editPriority,
        setEditPriority,
        isLoadingUpdatePriorities,
        updatePriorities
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useWallet = () => {
  const context = useContext(Context)

  return context
}
