import { useAccountHistory } from 'contexts/accountHistoryContext'
import { usePagination } from 'hooks/usePagination'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { HistoryPresentation } from './HistoryPresentation'

const AccountHistoryContainer = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [currentFilters, setCurrentFilters] = useState({})
  const { fetchAccountHistory, events, isLoading, eventsPagination } =
    useAccountHistory()
  const { state } = useLocation()
  const accountId = state?.account?.id
  const initialPage = 0
  const [activeTab, setActiveTab] = useState('Todos os status')

  const { currentPage, handleOnChangePage } = usePagination({
    initialPage: eventsPagination?.currentPage ?? 0
  })

  const fetchTransactions = async (
    pageNumber: number,
    additionalFilters: Record<string, any> = {}
  ) => {
    if (isFetching) {
      return
    }

    setIsFetching(true)

    try {
      const storedFilters = getStoredFilters()
      const selectedCardStatus = localStorage.getItem('selectedCardStatus')
      const parsedCardStatus = selectedCardStatus
        ? JSON.parse(selectedCardStatus)
        : undefined

      const fetchFilters = {
        ...storedFilters,
        ...additionalFilters,
        page: pageNumber,
        accountId,
        event: parsedCardStatus
      }

      setCurrentFilters(fetchFilters)

      await fetchAccountHistory({
        ...fetchFilters,
        filters: {
          onlyAccountWallets: fetchFilters.onlyAccountWallets,
          onlyCards: fetchFilters.onlyCards,
          event: fetchFilters.event
        }
      })
    } catch (error) {
      console.error('Error fetching transactions:', error)
    } finally {
      setIsFetching(false)
    }
  }

  const handleDateFilterApply = (startDate: string, endDate: string) => {
    const dateFilters = { startDate, endDate }
    const combinedFilters = { ...currentFilters, ...dateFilters }
    setStoredFilters(combinedFilters)
    fetchTransactions(initialPage, combinedFilters)
  }

  const handleDateFilterClear = () => {
    const clearedFilters = clearDateFilters()
    fetchTransactions(initialPage, clearedFilters)
  }

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber === currentPage) return
    handleOnChangePage(pageNumber)
    fetchTransactions(pageNumber, currentFilters)
  }

  const clearFilters = () => {
    localStorage.removeItem('accountHistoryFilters')
  }

  function getStoredFilters() {
    try {
      const filters = localStorage.getItem('accountHistoryFilters')
      return filters ? JSON.parse(filters) : {}
    } catch (error) {
      console.error('Failed to parse stored filters:', error)
      return {}
    }
  }

  function setStoredFilters(filters: Record<string, any>) {
    try {
      const existingFilters = getStoredFilters()
      const newFilters = { ...existingFilters, ...filters }
      localStorage.setItem('accountHistoryFilters', JSON.stringify(newFilters))
    } catch (error) {
      console.error('Failed to save filters:', error)
    }
  }

  function clearDateFilters() {
    const filters = getStoredFilters()
    delete filters.startDate
    delete filters.endDate
    localStorage.setItem('accountHistoryFilters', JSON.stringify(filters))
    return filters
  }

  const tabFilters: Record<string, Record<string, any>> = {
    'Todos os status': { onlyAccountWallets: false, onlyCards: false },
    'Status do cartão': { onlyAccountWallets: false, onlyCards: true },
    'Status de carteira': { onlyAccountWallets: true, onlyCards: false }
  }

  const handleTabSelected = (filters: Record<string, any>) => {
    const tab = filters.tab || ''

    const baseFilters = tabFilters[tab] || {}

    if (tab !== 'Status do cartão') {
      localStorage.removeItem('selectedCardStatus')
      baseFilters.event = undefined
    }

    const updatedFilters = { ...baseFilters }
    setCurrentFilters(updatedFilters)
    setStoredFilters(updatedFilters)
    fetchTransactions(currentPage, updatedFilters)

    setActiveTab(tab)
  }

  const handleCardStatusChange = (statuses: string[]) => {
    const cardStatusFilter = { event: statuses }
    const updatedFilters = { ...currentFilters, ...cardStatusFilter }
    setStoredFilters(updatedFilters)
    fetchTransactions(currentPage, updatedFilters)
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      clearFilters()
      localStorage.removeItem('selectedCardStatus')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    const selectedCardStatus = localStorage.getItem('selectedCardStatus')
    if (!selectedCardStatus) {
      fetchTransactions(currentPage, { event: undefined })
    } else {
      fetchTransactions(currentPage)
    }
    return () => clearFilters()
  }, [currentPage])

  return (
    <HistoryPresentation
      accountId={accountId}
      events={events}
      isLoading={isLoading}
      currentPage={currentPage}
      totalPages={eventsPagination?.totalPages || 1}
      totalResults={eventsPagination?.totalResults}
      handlePageChange={handlePageChange}
      handleOnChangePage={handleOnChangePage}
      onDateFilterApply={handleDateFilterApply}
      onDateFilterClear={handleDateFilterClear}
      lastPage={Boolean(eventsPagination?.lastPage)}
      onTabSelected={handleTabSelected}
      activeTab={activeTab}
      onCardStatusChange={handleCardStatusChange}
      tabFilters={tabFilters}
    />
  )
}

export default AccountHistoryContainer
