import { Center, Flex, Text, Button } from '@chakra-ui/react'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { type Product } from 'models/Product'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect } from 'react'

export type ProductCardProps = {
  productInfo: Product
}

const ProductCard = ({ productInfo }: ProductCardProps) => {
  const { selectProduct, selectedProduct, setEditPriority, editPriority } =
    useWallet()
  const { setStorageItem, getStorageItem } = useLocalStorage()

  const cachedProduct = getStorageItem('selectedProduct')

  const isSelected = selectedProduct?.productId === productInfo.productId
  const isDisabled =
    selectedProduct &&
    selectedProduct?.productId !== productInfo.productId &&
    editPriority

  const handleSelectProduct = () => {
    setEditPriority(false)
    selectProduct(productInfo)
    setStorageItem('selectedProduct', JSON.stringify(productInfo))
  }

  useEffect(() => {
    if (cachedProduct) selectProduct(JSON.parse(cachedProduct))
  }, [cachedProduct])

  const beforeStyles = {
    content: "''",
    position: 'absolute',
    top: '107px',
    left: 'calc(50% - 8px)',
    width: '16px',
    height: '12px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: "url('/img/wallet/productBorderIcon.png')",
    backgroundRepeat: 'no-repeat'
  }

  const defaultStyles = {
    boxShadow: !isDisabled && '1px 1px 0px 0px rgba(34, 28, 70, 0.20)',
    border: !isDisabled && '4px solid',
    borderColor: !isDisabled && '#1B51DC',
    bg: !isDisabled && '#FAF8FF',
    '&::before': !isDisabled && beforeStyles
  }

  return (
    <Flex
      as={Button}
      w="300px"
      h="112px"
      gap="12px"
      borderRadius="4px"
      padding="24px 16px"
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      bg={'#FAF8FF'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={defaultStyles}
      _focusVisible={defaultStyles}
      onClick={isDisabled ? undefined : handleSelectProduct}
      border={isSelected ? '4px solid' : '1px solid'}
      borderColor={isSelected ? '#1B51DC' : '#E5E3EA'}
      __css={
        isSelected
          ? {
              '&::before': beforeStyles
            }
          : {}
      }
    >
      <Flex justifyContent="flex-start" gap="8px">
        <Center bg="#fff" borderRadius="4px" w="32px" h="32px">
          <WalletIcon />
        </Center>
        <Text
          color={isDisabled ? '#969698' : '#221C46'}
          fontSize="24px"
          fontWeight="700"
          letterSpacing="-1.2px"
          lineHeight="31.2px"
        >
          {productInfo.name}
        </Text>
      </Flex>
      <Text
        color={isDisabled ? '#969698' : '#221C46'}
        fontSize="14px"
        fontWeight="600"
        lineHeight="20.3px"
        letterSpacing="-0.7px"
      >
        Operadora: {productInfo.cardProcessorName}
      </Text>
    </Flex>
  )
}

export default ProductCard

const WalletIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 6.75425H20.25V9.00447H3.75V6.75425ZM3.75 11.2547V17.2553H20.25V11.2547H3.75ZM1.5 4.50403H22.5V19.5055H1.5V4.50403ZM14.25 12.7548H18.75V15.0051H14.25V12.7548ZM13.5 12.7548H11.25V15.0051H13.5V12.7548Z"
      fill="#221C46"
    />
  </svg>
)
