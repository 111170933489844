import { Flex, Text } from '@chakra-ui/react'
import { Container, Layout, Toggle } from 'components/ui'
import { ControlledInput } from 'components/ui/Input'
import Mask from 'hooks/Masks'
import { useAlert } from 'hooks/useAlert'
import { WalletModel } from 'modules/wallet/domain/entities/Wallet'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isWalletType } from 'types/typeguards'
import { sanitizeValue } from 'utils/sanitizeObject'
import { acceptedOnlyNumbers } from 'utils/stringUtils'

import {
  MccArea,
  AreaLabel,
  FormSpacing,
  FormWrapper,
  ModelArea,
  CancelButton,
  SubmitButton,
  ViewWallet
} from './components'
import { WalletAddInputs } from './validations/walletAddSchema'

const WalletManagerTemplate = () => {
  return (
    <Layout maxWidth="1126px" isShowBackButton colorScheme="secondary">
      <Container>
        <Main />
      </Container>
    </Layout>
  )
}

export default WalletManagerTemplate

const Main = () => {
  const { id: walletId } = useParams()
  const isEditing = !!walletId

  const { alert } = useAlert()
  const navigate = useNavigate()
  const { state: currentProduct } = useLocation()
  const {
    createWallet,
    isLoadingCreate,
    errorCreate,
    updateWallet,
    isLoadingUpdate,
    errorUpdate,
    getWalletById,
    walletInfo
  } = useWallet()

  const [existingMccs, setExistingMccs] = useState<string[]>()

  const [maskedDailyValue, setMaskedDailyValue] = useState('0')
  const [maskedDailyWithdraw, setMaskedDailyWithdraw] = useState('0')
  const [maskedMonthlyWithdraw, setMaskedMonthlyWithdraw] = useState('0')
  const [maskedWithdrawFee, setMaskedWithdrawFee] = useState('0')
  const [walletName, setWalletName] = useState('')

  const {
    watch,
    reset,
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<WalletAddInputs>()

  const walletModel = watch('wallet.model')
  const allowTransfer = watch('wallet.allowTransfer')
  const allowWithdraw = watch('wallet.allowWithdraw')
  const isOpenWallet = watch('wallet.openWallet')
  const dailyTxUnlimited = watch('wallet.dailyTxUnlimited')
  const dailyWithdrawalTxUnlimited = watch('wallet.dailyWithdrawalTxUnlimited')
  const monthlyWithdrawalTxUnlimited = watch(
    'wallet.monthlyWithdrawalTxUnlimited'
  )

  const dailyLimitTx = watch('wallet.dailyLimitTx')
  const dailyLimitWithdrawTx = watch('wallet.dailyLimitWithdrawTx')
  const monthlyLimitWithdrawTx = watch('wallet.monthlyLimitWithdrawTx')

  const inputs =
    watch('wallet.name') &&
    watch('wallet.model') &&
    Number(maskedDailyValue) > 0

  const inputWithDailyTx = dailyTxUnlimited
    ? inputs
    : inputs && watch('wallet.dailyLimitTx')

  const inputWithMcc = watch('wallet.openWallet')
    ? inputs
    : inputs && watch('mccs')?.length >= 1

  const inputWithDailyWithdrawTx = dailyWithdrawalTxUnlimited
    ? inputs
    : inputs && watch('wallet.dailyLimitWithdrawTx')

  const inputWithMonthlyTx = monthlyWithdrawalTxUnlimited
    ? inputs
    : inputs && watch('wallet.monthlyLimitWithdrawTx')

  const inputWithAllowWithdraw = watch('wallet.allowWithdraw')
    ? inputs &&
      inputWithMonthlyTx &&
      inputWithDailyWithdrawTx &&
      Number(maskedDailyWithdraw) > 0 &&
      Number(maskedMonthlyWithdraw) > 0 &&
      Number(maskedWithdrawFee) > 0
    : inputs

  const requiredFields =
    inputWithDailyTx && inputWithMcc && inputWithAllowWithdraw

  const onSubmit: SubmitHandler<WalletAddInputs> = async formData => {
    const { wallet, mccs } = formData

    const isEmptyDailyTx = wallet.dailyLimitTx?.toString() === '0'
    const isEmptyDailyWithdrawTx =
      wallet.dailyLimitWithdrawTx?.toString() === '0'
    const isEmptyMonthlyLimitWithdrawTx =
      wallet.monthlyLimitWithdrawTx?.toString() === '0'

    if (
      isEmptyDailyTx ||
      isEmptyDailyWithdrawTx ||
      isEmptyMonthlyLimitWithdrawTx
    ) {
      isEmptyDailyTx && setError('wallet.dailyLimitTx', { message: 'invalid' })
      isEmptyDailyWithdrawTx &&
        setError('wallet.dailyLimitWithdrawTx', { message: 'invalid' })
      isEmptyMonthlyLimitWithdrawTx &&
        setError('wallet.monthlyLimitWithdrawTx', { message: 'invalid' })

      alert({
        id: 'minValues',
        title: 'Para poder prosseguir por favor insira valores acima de 0',
        status: 'error'
      })

      return
    }

    if (Number(maskedDailyValue) === 0) {
      return alert({
        id: 'errorDailyLimitValue',
        status: 'warning',
        title: 'Informe o valor de limite de transações diárias!'
      })
    }

    if (wallet.allowWithdraw && Number(maskedDailyWithdraw) === 0) {
      return alert({
        id: 'errorDailyLimitWiyhdrawValue',
        status: 'warning',
        title: 'Informe o valor de limite de saques diários!'
      })
    }

    if (wallet.allowWithdraw && Number(maskedMonthlyWithdraw) === 0) {
      return alert({
        id: 'errorMonthlyWithdraw',
        status: 'warning',
        title: 'Informe o valor de limite de saques mensais!'
      })
    }

    if (wallet.allowWithdraw && Number(maskedWithdrawFee) === 0) {
      return alert({
        id: 'errorMaskedWithdrawFee',
        status: 'warning',
        title: 'Informe o valor da taxa de saque!'
      })
    }

    const walletData = {
      wallet: {
        id: isEditing ? Number(walletId) : undefined,
        name: wallet.name,
        productId: currentProduct.productId,
        model: wallet.model as WalletModel,
        allowWithdraw: Boolean(wallet.allowWithdraw),
        allowTransfer: Boolean(wallet.allowTransfer),
        openWallet: Boolean(wallet.openWallet),
        withdrawFee: Number(maskedWithdrawFee),
        dailyLimitTx: Number(wallet.dailyLimitTx),
        dailyLimitValue: Number(maskedDailyValue),
        dailyLimitWithdrawValue: Number(maskedDailyWithdraw),
        monthlyLimitWithdrawValue: Number(maskedMonthlyWithdraw),
        dailyLimitWithdrawTx: Number(wallet.dailyLimitWithdrawTx),
        dailyTxUnlimited: Boolean(wallet.dailyTxUnlimited),
        dailyWithdrawalTxUnlimited: Boolean(wallet.dailyWithdrawalTxUnlimited),
        monthlyLimitWithdrawTx: Number(wallet.monthlyLimitWithdrawTx),
        monthlyWithdrawalTxUnlimited: Boolean(
          wallet.monthlyWithdrawalTxUnlimited
        )
      },
      mccs
    }

    if (!mccs.length && !wallet.openWallet) {
      return alert({
        id: 'invalidMCC',
        title:
          'Inclua as MCCs permitidas desta carteira ou defina ela como aberta',
        status: 'warning'
      })
    }

    if (isEditing && walletId) {
      updateWallet(walletData).then(data => {
        if (isWalletType(data)) {
          alert({
            id: 'WalletUpdatedSuccess',
            status: 'success',
            title: 'Carteira atualizada com sucesso'
          })

          navigate('/wallet')
        }

        return data
      })
    } else {
      createWallet(walletData).then(data => {
        if (isWalletType(data)) {
          alert({
            id: 'WalletCreatedSuccess',
            status: 'success',
            title: 'Carteira criada com sucesso'
          })

          navigate('/wallet')
        }

        return data
      })
    }
  }

  useEffect(() => {
    if (!currentProduct) {
      navigate('/wallet')
      alert({
        id: 'NoSelectedProduct',
        status: 'warning',
        title: 'Selecione um produto antes de criar uma carteira!'
      })
    }
  }, [currentProduct])

  useEffect(() => {
    const errors = errorCreate?.response?.data
    if (errors?.length) {
      errors?.map(({ message }) => {
        alert({
          id: crypto.randomUUID(),
          status: 'error',
          title: message
        })
      })
    }
  }, [errorCreate])

  useEffect(() => {
    const errors = errorUpdate?.response?.data
    if (errors) {
      errors?.map(({ message }) => {
        alert({
          id: crypto.randomUUID(),
          status: 'error',
          title: message
        })
      })
    }
  }, [errorUpdate])

  useEffect(() => {
    if (isEditing && walletId) getWalletById({ walletId: Number(walletId) })
  }, [isEditing, walletId])

  useEffect(() => {
    if (walletInfo && isEditing) {
      setExistingMccs(walletInfo?.mccs)

      setMaskedWithdrawFee(String(walletInfo.withdrawFee))
      setMaskedDailyValue(String(walletInfo.dailyLimitValue))
      setMaskedDailyWithdraw(
        walletInfo.dailyLimitWithdrawValue
          ? String(walletInfo.dailyLimitWithdrawValue)
          : '0'
      )

      setMaskedMonthlyWithdraw(
        walletInfo.monthlyLimitWithdrawValue
          ? String(walletInfo.monthlyLimitWithdrawValue)
          : '0'
      )

      reset({
        wallet: {
          id: walletInfo.id,
          name: walletInfo.name,
          model: walletInfo.model,
          withdrawFee: walletInfo.withdrawFee,
          productId: walletInfo.productId,
          allowWithdraw: walletInfo.allowWithdraw,
          dailyLimitTx: walletInfo.dailyLimitTx,
          dailyLimitWithdrawTx: walletInfo.dailyLimitWithdrawTx,
          dailyTxUnlimited: walletInfo.dailyLimitTx >= 999999,
          monthlyLimitWithdrawTx: walletInfo.monthlyLimitWithdrawTx,
          openWallet: walletInfo.openWallet,
          monthlyWithdrawalTxUnlimited:
            walletInfo.monthlyLimitWithdrawTx! >= 999999,
          dailyWithdrawalTxUnlimited:
            walletInfo.dailyLimitWithdrawTx! >= 999999,
          allowTransfer:
            walletInfo.allowTransferAux || walletInfo.allowTransferPat
        }
      })
    }
  }, [walletInfo])

  useEffect(() => {
    if (!isEditing && walletInfo?.name) {
      const sanitizedValue = sanitizeValue(walletInfo.name)
      setWalletName(sanitizedValue)
      setValue('wallet.name', sanitizedValue)
    }
  }, [isEditing, walletInfo])

  return (
    <Flex
      flexDir="column"
      gap="40px"
      maxW="1126px"
      width="100%"
      p="40px"
      m="0 auto"
    >
      <FormSpacing>
        <FormWrapper
          heading={isEditing ? 'Editar carteira' : 'Criar carteira'}
          subHeading="Informações gerais"
          WalletInfo={
            isEditing ? (
              <ViewWallet name={walletInfo?.name} status={walletInfo?.status} />
            ) : undefined
          }
        >
          <Flex flexDir="column" gap="8px">
            <AreaLabel title="Carteira" />

            <Flex gap="16px" w="50%">
              {isEditing ? (
                <Flex flexDir="column" gap="16px" w="100%">
                  <Text
                    letterSpacing="-0.7px"
                    fontSize="14px"
                    fontWeight="600"
                    color="#463F5F"
                  >
                    Nome da carteira
                  </Text>
                  <Text
                    height="48px"
                    color="#221C46"
                    fontSize="16px"
                    fontWeight="600"
                    letterSpacing="-0.8px"
                  >
                    {walletInfo?.name ?? '-'}
                  </Text>
                </Flex>
              ) : (
                <ControlledInput
                  id="wallet.name"
                  title="Nome da carteira"
                  isInvalid={!!errors.wallet?.name}
                  isRequired
                  errorMessage="Nome da carteira é obrigatório"
                  placeholder="Insira um nome para sua carteira"
                  value={walletName}
                  onChange={event => {
                    const sanitizedValue = sanitizeValue(event.target.value)
                    setWalletName(sanitizedValue)
                    setValue('wallet.name', sanitizedValue)
                  }}
                  height="48px"
                  register={register}
                />
              )}
            </Flex>
          </Flex>

          <Flex w="60%" gap="8px">
            <ModelArea currentModel={walletModel} setValue={setValue} />

            <Flex flexDir="column" w="100%">
              <Flex mb="4px">
                <AreaLabel title="Permissões" />
              </Flex>

              <Toggle
                register={register}
                id="wallet.allowTransfer"
                title="Permite transferência"
                tooltip="Habilite este campo apenas se a carteira permitir que o colaborador transfira valores entre carteiras"
                isChecked={allowTransfer}
                onChange={() =>
                  setValue('wallet.allowTransfer', !allowTransfer)
                }
              />
            </Flex>
          </Flex>
        </FormWrapper>

        <FormWrapper subHeading="Informações de transação">
          <Flex w="100%" flexDir="column" gap="8px">
            <AreaLabel title="Limites diários" />

            <Flex gap="16px">
              <Flex w="100%" gap="8px" flexDir="column">
                <ControlledInput
                  height="48px"
                  register={register}
                  value={dailyLimitTx}
                  isDisabled={dailyTxUnlimited}
                  isRequired={!dailyTxUnlimited}
                  id="wallet.dailyLimitTx"
                  title="Limite de transações diárias"
                  isInvalid={!!errors.wallet?.dailyLimitTx}
                  placeholder="Informe a quantidade de transações"
                  onChange={event =>
                    setValue(
                      'wallet.dailyLimitTx',
                      Number(acceptedOnlyNumbers(event.target.value, 30))
                    )
                  }
                />

                <Toggle
                  id="wallet.dailyTxUnlimited"
                  register={register}
                  title="Transações diárias ilimitadas"
                  isChecked={dailyTxUnlimited}
                  onChange={() =>
                    setValue('wallet.dailyTxUnlimited', !dailyTxUnlimited)
                  }
                />
              </Flex>

              <ControlledInput
                register={register}
                value={
                  maskedDailyValue === '0'
                    ? undefined
                    : Mask.moneyInput(String(maskedDailyValue))
                }
                id="wallet.dailyLimitValue"
                title="Limite diário em R$"
                isInvalid={!!errors.wallet?.dailyLimitValue}
                isRequired
                placeholder="R$ 0,00"
                onChange={event => {
                  setMaskedDailyValue(
                    Mask.removeMoneyInput(event.currentTarget.value)
                  )
                }}
                height="48px"
              />
            </Flex>
          </Flex>

          <MccArea
            register={register}
            setValue={setValue}
            isInvalid={!!errors.mccs}
            isDisabled={isOpenWallet}
            existingMccs={existingMccs}
            checkedOpenWallet={isOpenWallet}
            setCheckedOpenWallet={() =>
              setValue('wallet.openWallet', !isOpenWallet)
            }
          />
        </FormWrapper>

        <FormWrapper subHeading="Informações de saque">
          <Flex w="100%" flexDir="column" gap="8px">
            <AreaLabel title="Permissões" />
            <Toggle
              id="wallet.allowWithdraw"
              register={register}
              title="Permite saque"
              tooltip="Habilite este campo apenas se a carteira permitir que o colaborador realize saques"
              isChecked={allowWithdraw}
              onChange={() => setValue('wallet.allowWithdraw', !allowWithdraw)}
            />
          </Flex>

          <Flex w="100%" flexDir="column" gap="8px">
            <AreaLabel title="Limites diários" />

            <Flex gap="16px">
              <Flex w="100%" gap="8px" flexDir="column">
                <ControlledInput
                  height="48px"
                  register={register}
                  value={dailyLimitWithdrawTx}
                  id="wallet.dailyLimitWithdrawTx"
                  title="Limite de saques diários"
                  placeholder="Informe a quantidade de saques"
                  isDisabled={dailyWithdrawalTxUnlimited || !allowWithdraw}
                  isRequired={!dailyWithdrawalTxUnlimited && allowWithdraw}
                  isInvalid={!!errors.wallet?.dailyLimitWithdrawTx}
                  onChange={event =>
                    setValue(
                      'wallet.dailyLimitWithdrawTx',
                      Number(acceptedOnlyNumbers(event.target.value, 30))
                    )
                  }
                />

                <Toggle
                  id="wallet.dailyWithdrawalTxUnlimited"
                  register={register}
                  title="Quantidade de saques diários ilimitados"
                  isDisabled={!allowWithdraw}
                  isChecked={dailyWithdrawalTxUnlimited}
                  onChange={() =>
                    setValue(
                      'wallet.dailyWithdrawalTxUnlimited',
                      !dailyWithdrawalTxUnlimited
                    )
                  }
                />
              </Flex>

              <ControlledInput
                height="48px"
                register={register}
                id="wallet.dailyLimitWithdrawValue"
                title="Limite diário em R$"
                isInvalid={!!errors.wallet?.dailyLimitWithdrawValue}
                isDisabled={!allowWithdraw}
                isRequired={allowWithdraw}
                placeholder="R$ 0,00"
                value={Mask.moneyInput(String(maskedDailyWithdraw))}
                onChange={event => {
                  setMaskedDailyWithdraw(
                    Mask.removeMoneyInput(event.currentTarget.value)
                  )
                }}
              />
            </Flex>
          </Flex>

          <Flex w="100%" flexDir="column" gap="8px">
            <AreaLabel title="Limites mensais" />

            <Flex gap="16px">
              <Flex w="100%" gap="8px" flexDir="column">
                <ControlledInput
                  height="48px"
                  register={register}
                  value={monthlyLimitWithdrawTx}
                  isDisabled={monthlyWithdrawalTxUnlimited || !allowWithdraw}
                  isRequired={!monthlyWithdrawalTxUnlimited && allowWithdraw}
                  id="wallet.monthlyLimitWithdrawTx"
                  title="Limite de saques mensais"
                  isInvalid={!!errors.wallet?.monthlyLimitWithdrawTx}
                  placeholder="Informe a quantidade de saques"
                  onChange={event =>
                    setValue(
                      'wallet.monthlyLimitWithdrawTx',
                      Number(acceptedOnlyNumbers(event.target.value, 30))
                    )
                  }
                />

                <Toggle
                  id="wallet.monthlyWithdrawalTxUnlimited"
                  register={register}
                  title="Quantidade de saques mensais ilimitados"
                  isDisabled={!allowWithdraw}
                  isChecked={monthlyWithdrawalTxUnlimited}
                  onChange={() =>
                    setValue(
                      'wallet.monthlyWithdrawalTxUnlimited',
                      !monthlyWithdrawalTxUnlimited
                    )
                  }
                />
              </Flex>

              <ControlledInput
                height="48px"
                register={register}
                id="wallet.monthlyLimitWithdrawValue"
                title="Limite mensal em R$"
                isInvalid={!!errors.wallet?.monthlyLimitWithdrawValue}
                isDisabled={!allowWithdraw}
                isRequired={allowWithdraw}
                placeholder="R$ 0,00"
                value={Mask.moneyInput(String(maskedMonthlyWithdraw))}
                onChange={event => {
                  setMaskedMonthlyWithdraw(
                    Mask.removeMoneyInput(event.currentTarget.value)
                  )
                }}
              />
            </Flex>
          </Flex>

          <Flex w="100%" flexDir="column" gap="8px">
            <AreaLabel title="Taxas" />

            <Flex gap="16px">
              <ControlledInput
                height="48px"
                register={register}
                value={
                  maskedWithdrawFee === '0'
                    ? undefined
                    : Mask.moneyInput(String(maskedWithdrawFee))
                }
                id="wallet.withdrawFee"
                title="Taxa de saque"
                isDisabled={!allowWithdraw}
                isInvalid={!!errors.wallet?.withdrawFee}
                errorMessage="Taxa de saque é obrigatório"
                placeholder="R$ 0,00"
                onChange={event => {
                  setMaskedWithdrawFee(
                    Mask.removeMoneyInput(event.currentTarget.value)
                  )
                }}
              />

              <Flex w="100%" />
            </Flex>
          </Flex>
        </FormWrapper>
      </FormSpacing>

      <Flex alignSelf="end" gap="16px">
        <CancelButton onClick={() => navigate('/wallet')} />
        <SubmitButton
          isLoading={isLoadingCreate || isLoadingUpdate}
          isDisabled={isLoadingCreate || isLoadingUpdate || !requiredFields}
          title={isEditing ? 'Salvar alterações' : 'Criar carteira'}
          onClick={handleSubmit(onSubmit)}
        />
      </Flex>
    </Flex>
  )
}
