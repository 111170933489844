import { Center, Flex, Text as ChakraText } from '@chakra-ui/react'
import React from 'react'

import CardStatusFilter from '../CardStatusFilter'
import DateFilter from '../DateFilter'
import TabComponent from '../Tab'
import ResultsTableContainer from '../Table/TableContainer'

export const HistoryPresentation = ({
  accountId,
  events,
  isLoading,
  currentPage,
  totalPages,
  totalResults,
  lastPage,
  handlePageChange,
  handleOnChangePage,
  onDateFilterApply,
  onDateFilterClear,
  onTabSelected,
  activeTab,
  tabFilters,
  onCardStatusChange
}: {
  accountId?: number
  events: any[]
  isLoading: boolean
  currentPage: number
  totalPages: number
  totalResults?: number
  lastPage: boolean
  onDateFilterClear: () => void
  handlePageChange: (pageNumber: number) => void
  handleOnChangePage: (pageNumber: number) => void
  onDateFilterApply: (startDate: string, endDate: string) => void
  onTabSelected: (filters: Record<string, any>) => void
  activeTab: string
  onCardStatusChange: (statuses: string[]) => void
  tabFilters: Record<string, Record<string, any>>
}) => {
  return (
    <Flex p="16px 40px" mt="16px">
      <Flex
        flexDir="column"
        maxW="1046px"
        bg="white"
        width="100%"
        p="40px"
        gap="24px"
        margin="0 auto"
        boxShadow="1px 1px 0px 0px rgba(34, 28, 70, 0.20)"
      >
        <Flex
          alignItems="flex-start"
          justifyContent="center"
          gap="24px"
          flexDir="column"
        >
          <Center bg="#00EB5E" borderRadius="4px" w="40px" h="40px">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 6.50195H20.5V9.00195H3.5V6.50195ZM3.5 11.002V17.502H20.5V11.002H3.5ZM1.5 4.50195H22.5V19.502H1.5V4.50195ZM14 13.002H19V15.002H14V13.002ZM13 13.002H11V15.002H13V13.002Z"
                fill="#221C46"
              />
            </svg>
          </Center>
          <Flex flexDir="column" gap="8px">
            <ChakraText
              color="#221C46"
              fontWeight="700"
              fontSize="24px"
              letterSpacing="-0.8px"
            >
              Histórico da conta
            </ChakraText>
            <ChakraText
              color="#463F5F"
              fontSize="18px"
              fontWeight="500"
              letterSpacing="-0.8px"
            >
              Conta #{accountId}
            </ChakraText>
          </Flex>

          <Flex w="100%" justifyContent="space-between">
            <TabComponent
              tabs={Object.keys(tabFilters)}
              onTabSelected={onTabSelected}
              tabFilters={tabFilters}
            />

            <DateFilter
              onApply={onDateFilterApply}
              onClear={onDateFilterClear}
            />
          </Flex>
        </Flex>

        {activeTab === 'Status do cartão' && (
          <CardStatusFilter onCardStatusChange={onCardStatusChange} />
        )}

        <ResultsTableContainer
          events={events}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalResults}
          handlePageChange={handlePageChange}
          handleOnChangePage={handleOnChangePage}
          lastPage={lastPage}
        />
      </Flex>
    </Flex>
  )
}
