import React from 'react'

import CancelButtonPresentational from '../presentational/CancelButtonPresentational'

type CancelButtonContainerProps = {
  onClick: () => void
}

export function CancelButtonContainer({ onClick }: CancelButtonContainerProps) {
  return <CancelButtonPresentational onClick={onClick} />
}

export default CancelButtonContainer
