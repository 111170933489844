import React from 'react'

import SubmitButtonPresentational from '../presentational/SubmitButtonPresentational'

type SubmitButtonContainerProps = {
  title: string
  isLoading: boolean
  isDisabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export function SubmitButtonContainer({
  title,
  isLoading,
  isDisabled,
  onClick
}: SubmitButtonContainerProps) {
  return (
    <SubmitButtonPresentational
      title={title}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  )
}

export default SubmitButtonContainer
