import { useLocalStorage } from 'hooks/useLocalStorage'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect } from 'react'

import WalletsListPresentational from '../presentational/WalletsListPresentational'

export type WalletListProps = {
  header: React.ReactNode
}

export function WalletsListContainer({ header }: WalletListProps) {
  const { removeStorageItem } = useLocalStorage()
  const { selectedProduct, getWalletsByProductId, isLoading, wallets } =
    useWallet()

  const productId = selectedProduct?.productId

  const removeCachedProduct = () => removeStorageItem('selectedProduct')

  useEffect(() => {
    if (!productId) return
    getWalletsByProductId({ productId: Number(productId) })
  }, [productId])

  useEffect(() => {
    window.addEventListener('beforeunload', removeCachedProduct)
    return () => {
      window.removeEventListener('beforeunload', removeCachedProduct)
    }
  }, [removeCachedProduct, getWalletsByProductId])

  return (
    <WalletsListPresentational
      header={header}
      wallets={wallets}
      isLoading={isLoading}
    />
  )
}
