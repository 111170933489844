import { useOktaAuth } from '@okta/okta-react'
import { Loading } from 'components/ui'
import ComposeProviders from 'components/ui/ComposeProviders'
import { ProfileProvider, useProfile } from 'contexts/profileContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { useEffect } from 'react'
import { HomeTemplate } from 'templates'
import { isDevSide } from 'utils/envUtils'
import { objectIsEmpty } from 'utils/objectUtils'

const Home = () => {
  const { user, isLoadingProfile, isSuccessProfile } = useProfile()
  const { authState, oktaAuth } = useOktaAuth()
  const { getStorageItem } = useLocalStorage()

  const ignoreAuthOkta = getStorageItem('ignoreAuthOkta')

  useEffect(() => {
    if (ignoreAuthOkta === 'true') return

    if (!isLoadingProfile && isSuccessProfile) {
      if (user && !authState?.isAuthenticated && !isDevSide()) {
        oktaAuth.signInWithRedirect()
      }
    } else if (objectIsEmpty(getStorageItem('okta-token-storage'))) {
      oktaAuth.signInWithRedirect()
    }
  }, [isLoadingProfile, isSuccessProfile, user, authState, ignoreAuthOkta])

  if (isLoadingProfile) return <Loading />

  const providers = [{ Provider: ProfileProvider }]

  return (
    <ComposeProviders providers={providers}>
      <HomeTemplate />
    </ComposeProviders>
  )
}

export default Home
