import { useDisclosure } from '@chakra-ui/react'
import { GetTransactionResponse } from 'modules/transaction/infra/dto/GetTransactionResponse'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatConsumerModel } from 'utils/productUtils'

import { TransactionAccordionPresentational } from '../presentational/TransactionAccordionPresentational'

export const TransactionAccordionContainer = ({
  account,
  transaction,
  consumer,
  customer,
  accountWalletDetails
}: GetTransactionResponse) => {
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

  const { creationDate, productId, closureDate, id: accountId } = account
  const wallet = accountWalletDetails[0]

  const isUnlimitedDailyTx = wallet.dailyLimitTx >= 999999

  const formattedCreationDate = formatDateToBrazilianFormat(creationDate)

  const formattedClosureDate = closureDate
    ? formatDateToBrazilianFormat(closureDate)
    : '-'

  const { formattedConsumerModel } = formatConsumerModel({
    isPatCustomer: customer.product!.patCustomer
  })

  const handleOnClickConsumer = () => {
    navigate(`/consumer/details/${accountId}`, {
      state: {
        isFromTransactionPage: true,
        account,
        consumer,
        customer
      }
    })
  }

  return (
    <TransactionAccordionPresentational
      accountId={String(accountId)}
      productId={String(productId)}
      transaction={transaction}
      wallet={wallet}
      consumer={consumer}
      customer={customer}
      isUnlimitedDailyTx={isUnlimitedDailyTx}
      formattedCreationDate={formattedCreationDate}
      formattedClosureDate={formattedClosureDate}
      formattedConsumerModel={formattedConsumerModel}
      isOpen={isOpen}
      onToggle={onToggle}
      goToConsumerPage={handleOnClickConsumer}
    />
  )
}
