import { Center, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

import { CancelButtonContainer } from '../../Wrapper/container/CancelButtonContainer'
import { SubmitButtonContainer } from '../../Wrapper/container/SubmitButtonContainer'
import { WrapperContainer } from '../../Wrapper/container/WrapperContainer'

type InactiveWalletPresentationalProps = {
  walletName: string
  onClose: () => void
  handleInactiveWallet: () => void
  isLoadingInactive: boolean
  isOpen: boolean
}

export default function InactiveWalletPresentational({
  walletName,
  onClose,
  handleInactiveWallet,
  isLoadingInactive,
  isOpen
}: InactiveWalletPresentationalProps) {
  if (!isOpen) return null

  return (
    <WrapperContainer>
      <Header />
      <Flex
        flexDir="column"
        alignItems="center"
        gap="40px"
        p="40px"
        textAlign="center"
      >
        <Flex flexDir="column" gap="8px">
          <Text
            fontWeight="800"
            fontSize="32px"
            letterSpacing="-1.6px"
            color="#221C46"
          >
            Inativar carteira
          </Text>

          <Flex gap="4px" fontSize="20px" color="#5A5469" letterSpacing="-1px">
            <Text fontWeight="500">Deseja inativar a carteira</Text>
            <Text fontWeight="700">{walletName}?</Text>
          </Flex>
        </Flex>

        <Flex gap="16px">
          <CancelButtonContainer onClick={onClose} />
          <SubmitButtonContainer
            onClick={handleInactiveWallet}
            title="Inativar carteira"
            isLoading={isLoadingInactive}
            isDisabled={false}
          />
        </Flex>
      </Flex>
    </WrapperContainer>
  )
}

function Header() {
  return (
    <Center h="160px" w="100%" bg="#FCF1EE">
      <Flex
        position="relative"
        _before={{
          content: "''",
          position: 'absolute',
          top: '128px',
          left: 'calc(50% - 24px)',
          width: '48px',
          height: '24px',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: "url('/img/wallet/inactiveArrowDown.svg')",
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Image
          w="96px"
          h="96px"
          alt="Inactive Wallet Icon"
          src="/img/wallet/inactiveWalletIcon.svg"
        />
      </Flex>
    </Center>
  )
}
