import { useAlert } from 'hooks/useAlert'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect } from 'react'

import DeleteWalletPresentational from '../presentational/DeleteWalletPresentational'

type DeleteWalletContainerProps = {
  walletId: number
  walletName: string
  onClose: () => void
  onComplete: () => void
  isOpen: boolean
}

export function DeleteWalletContainer({
  walletId,
  walletName,
  onClose,
  onComplete,
  isOpen
}: DeleteWalletContainerProps) {
  const { alert } = useAlert()
  const { removeWallet, isLoadingRemove } = useWallet()

  const handleDeleteWallet = () => {
    removeWallet(walletId)
      .then(() => {
        onClose()
        onComplete()
        alert({
          id: 'deleteWalletToast',
          title: 'Carteira excluída com sucesso',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)
        alert({
          id: 'errorDeleteWalletToast',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente',
          status: 'error'
        })
      })
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose()
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return (
    <DeleteWalletPresentational
      walletName={walletName}
      onClose={onClose}
      handleDeleteWallet={handleDeleteWallet}
      isLoadingRemove={isLoadingRemove}
      isOpen={isOpen}
    />
  )
}

export default DeleteWalletContainer
