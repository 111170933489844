import { useAlert } from 'hooks/useAlert'
import { useWallet } from 'modules/wallet/presentation/contexts/WalletContext'
import React, { useEffect } from 'react'

import ActiveWalletPresentational from '../presentational/ActiveWalletPresentational'

type ActiveWalletContainerProps = {
  walletId: number
  walletName: string
  onClose: () => void
  onComplete: () => void
  isOpen: boolean
}

export function ActiveWalletContainer({
  walletId,
  walletName,
  onClose,
  onComplete,
  isOpen
}: ActiveWalletContainerProps) {
  const { alert } = useAlert()
  const { activeWallet, isLoadingActive } = useWallet()

  const handleActiveWallet = () => {
    activeWallet(walletId)
      .then(() => {
        onClose()
        onComplete()

        alert({
          id: 'activeWalletToast',
          title: 'Carteira ativada com sucesso',
          status: 'success'
        })
      })
      .catch(err => {
        console.error(err)
        alert({
          id: 'errorActiveWalletToast',
          title:
            'Ops! Tivemos um problema ao processar sua solicitação, por favor tente novamente',
          status: 'error'
        })
      })
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose()
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return (
    <ActiveWalletPresentational
      walletName={walletName}
      onClose={onClose}
      handleActiveWallet={handleActiveWallet}
      isLoadingActive={isLoadingActive}
      isOpen={isOpen}
    />
  )
}

export default ActiveWalletContainer
