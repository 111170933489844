import { Product } from 'models/Product'
import React, { useEffect, useState } from 'react'
import { WalletService } from 'services/walletService'

import ProductsPresentational from '../presentational/ProductsPresentational'

export function ProductsContainer() {
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setIsLoading] = useState(false)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await WalletService.getProducts()
      setProducts(data.products)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return <ProductsPresentational products={products} loading={loading} />
}
